export const getDisplayName = (social) => {
  if (social.platform === 'youtube') return social.platform_account_nickname

  if (['facebook'].includes(social.platform)) return social.platform_account_nickname

  return social.platform_account_nickname
}

export const getSocialProfileUrl = (social) => {
  if (social.profile_url) return social.profile_url

  if (social.platform === 'youtube')
    return `https://www.youtube.com/channel/${social.platform_account_id}`

  if (social.platform === 'facebook') {
    return `https://www.facebook.com/${social.platform_account_nickname}`
  }
}

export const getSocialName = (platform) => {
  if (platform === 'youtube') return 'YouTube'
  if (platform === 'tiktok') return 'TikTok'
  if (platform === 'instagram') return 'Instagram'
  if (platform === 'twitter') return 'Twitter'
  if (platform === 'facebook') return 'Facebook'
  if (platform === 'twitch') return 'Twitch'
  if (platform === 'linkedin') return 'LinkedIn'
  if (platform === 'pinterest') return 'Pinterest'
  if (platform === 'reddit') return 'Reddit'
  if (platform === 'soundcloud') return 'SoundCloud'
  if (platform === 'spotify') return 'Spotify'
  if (platform === 'vimeo') return 'Vimeo'
  if (platform === 'vine') return 'Vine'
  if (platform === 'vk') return 'VK'
  if (platform === 'weibo') return 'Weibo'
  if (platform === 'whatsapp') return 'WhatsApp'
  if (platform === 'wordpress') return 'WordPress'
  return platform
}
