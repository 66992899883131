import React, { useState } from 'react'
import Select from 'react-select'
import _ from 'lodash'
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/hooks'
import keyword_groups from './keyword_groups.json'

import { setSelectedTags, tagInputSelector } from 'redux/searchSlice'

export default function KeywordSearch({
  inputPlaceholder = 'keyword search',
  searchWithParams,
  keywordGroup,
}) {
  const dispatch = useDispatch()

  if (keywordGroup) {
    inputPlaceholder = keywordGroup
  }

  const allChoices = useSelector((state) => {
    if (!keywordGroup) {
      return state.search.availableTags
    } else {
      return keyword_groups[keywordGroup]
    }
  })
  const currentTags = useSelector((state) => state.search.params.tags)

  const suggestedTags = allChoices.map((t) => ({ label: t, value: t }))

  const currentSelectedTags = useSelector((state) => state.search.selectedTags)

  const handleInputChange = (selectedOption) => {
    const newTags = [
      ...currentSelectedTags.filter((tag) => {
        return !allChoices.includes(tag.value)
      }),
      ...selectedOption,
    ]

    dispatch(setSelectedTags(newTags))
    // setSelectedTags(selectedOption)

    searchWithParams()
  }

  const tagOptions = suggestedTags.filter((option) => !currentTags.includes(option.value))

  const tagInput = useSelector((state) =>
    state.search.selectedTags.filter((tag) => {
      return allChoices.includes(tag.value)
    }),
  )

  return (
    <div>
      <form
        id="tag-form"
        className="mb-3"
        onSubmit={(e) => {
          e.preventDefault()
          console.log('send form')
          // if (tagInput) {
          //   setSelectedTags([])
          // }
        }}
      >
        <div className="d-flex flex-md-row">
          <div className="w-100">
            <Select
              options={tagOptions}
              value={tagInput}
              isMulti={true}
              placeholder={inputPlaceholder}
              isClearable={true}
              onChange={handleInputChange}
            />
          </div>
          {/* <div>
            <button
              className="btn btn-primary d-none btn-sm ms-md-2 ms-0 mt-2 mt-md-0"
              data-purpose="add-tag"
              type="submit"
              value="Add"
            />
          </div> */}
        </div>
      </form>
    </div>
  )
}
