import { useNavigate, useLocation, Link, useRouteError } from 'react-router-dom'

const ErrorWrapper = (props) => {
  const navigate = useNavigate()
  let error = useRouteError()
  console.error(error)

  return (
    <div className="container mt-3" role="alert">
      <h2 className="my-3">Gaybors Marketplace</h2>
      <p>We're sorry! Something went wrong while trying to access this page.</p>
      <p>
        If you keep getting an error, please <Link to="/search/contact-tpp">contact us</Link> to let us
        know what is happening.
      </p>
      <p>
        <span
          onClick={() => {
            navigate(-1)
          }}
          className="btn btn-link"
        >
          Click here
        </span>
        to go back to the previous page you were on
      </p>
    </div>
  )
}

export default ErrorWrapper
