import { Navigate } from 'react-router-dom'

import { useAppSelector as useSelector } from 'hooks/hooks'
import config from '../config'

function PreLogin(props) {
  const user = useSelector((state) => state.user)

  if (user.loaded && user.authed) {
    return <Navigate to="/" />
  }
  return (
    <div className="shared-prelogin bg-gray-800">
      <div className="login-container">
        <div className="container mt-4">
          <div className="row justify-content-center mx-1">
            <div className="col-md-10 col-lg-8 signin-main p-5">
              <div className="logo-container">
                <img src={config.gayborDarkImgURL} alt="Company Logo" className="company-logo" />
              </div>
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PreLogin
