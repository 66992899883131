import { addMarkdownLineBreaks, removeEmails } from 'utils'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

function RenderMarkdown({ markdownText, showEmails = true }) {
  if (!showEmails) {
    markdownText = removeEmails(markdownText)
  }
  return (
    <ReactMarkdown
      children={addMarkdownLineBreaks(markdownText)}
      remarkPlugins={[remarkGfm]}
      components={{
        a: ({ node, ...props }) => <a {...props} target="_blank" rel="noopener noreferrer" />,
      }}
    />
  )
}

export default RenderMarkdown
