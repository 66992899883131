import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
// import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/hooks'
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/hooks'
import { selectCampaign, createCampaign, updateNewCampaign } from './redux/campaignsSlice'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'

const CampaignChooserModal = ({ show, handleClose }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const newCampaign = useSelector((state) => state.campaigns.newCampaign)

  const campaigns = useSelector((state) => state.campaigns.entities)

  const handleSelect = (choice) => {
    dispatch(selectCampaign(choice))
    handleClose()
  }

  const [createNew, setCreateNew] = useState(false)

  const choices = [
    {
      display: 'Favorites',
      value: 'favorites',
    },
    ..._.orderBy(campaigns, (c) => Date(c.created_at), 'desc').map((campaign) => ({
      display: campaign.title,
      value: campaign.id,
    })),
  ]

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Choose Campaign or Favorites</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul className="list-group">
          {choices.map((choice, index) => (
            <li
              className={`list-group-item list-group-item-action text-center selectable`}
              key={index}
              onClick={() => handleSelect(choice.value)}
            >
              {choice.display}
            </li>
          ))}
        </ul>
        <div className="row mt-2">
          <div className="col-12 text-center">
            {createNew ? (
              <form
                onSubmit={(e) => {
                  e.preventDefault()
                  dispatch(createCampaign(newCampaign))
                  setCreateNew(false)
                }}
              >
                <input
                  type="text"
                  className="form-control text-center"
                  id="campaign-title"
                  aria-describedby="campaign-title"
                  value={newCampaign.title}
                  onChange={(e) => {
                    dispatch(updateNewCampaign({ title: e.target.value }))
                  }}
                />
                <input type="submit" className="btn btn-primary mt-2" value="Create" />
              </form>
            ) : (
              <Button
                variant="primary"
                onClick={() => {
                  // handleSelect(null)
                  setCreateNew(true)
                  // navigate('/search/campaigns')
                }}
              >
                Create New Campaign
              </Button>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CampaignChooserModal
