import { Navigate } from 'react-router-dom'

import { useAppSelector as useSelector } from 'hooks/hooks'
import config from 'config'

function LoginContainer(props) {
  const user = useSelector((state) => state.user)

  if (user.loaded && user.authed) {
    return <Navigate to="/search" />
  }
  return (
    <div className={`login-container ${config.preferredBackgroundClass}`}>
      <div className="container mt-4">
        <div className="row justify-content-center mx-1">
          <div className="col-md-10 col-lg-8 signin-main p-5">
            <div className="logo-container">
              <img
                style={{
                  width: '200px',
                }}
                src={config.gayborDarkImgURL}
                alt="Company Logo"
                className="company-logo"
              />
            </div>
            {props.children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginContainer
