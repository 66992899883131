import React, { useEffect, useState } from 'react'
import { changeSetting, fetchSettings } from './redux/userSlice'
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/hooks'

import _ from 'lodash'
import axios from 'axios'
import LoadingContainer from 'components/LoadingContainer'

export default function UserSettings() {
  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const [saveNotification, setNotification] = useState(null)
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordMessage, setPasswordMessage] = useState(null)

  const [selectedMenuItem, setSelectedMenuItem] = useState('personal')

  const menuItems = [
    {
      id: 'personal',
      name: 'Personal Details',
    },
    // {
    //   id: 'password',
    //   name: 'Change Password',
    // },
  ]

  useEffect(() => {
    dispatch(fetchSettings())
  }, [dispatch])

  const handleSubmit = (e) => {
    e.preventDefault()
    const data = {
      full_name: user.settings.full_name.trim(),
      nickname: user.settings.nickname.trim(),
    }
    console.log(data)
    axios.post('/api/user/configuration', data).then(() => {
      setNotification('Settings Saved!')
      window.setTimeout(() => {
        setNotification(null)
      }, 1500)
    })
  }

  const handleChangePasswordSubmit = async (e) => {
    e.preventDefault()
    const data = {
      old_password: oldPassword,
      new_password: newPassword,
    }

    if (newPassword !== confirmPassword) {
      setPasswordMessage('New password and confirmation do not match')
      window.setTimeout(() => {
        setPasswordMessage(null)
      }, 1800)
      return
    }

    if (newPassword.length < 6) {
      setPasswordMessage('Choose a longer password')
    }

    const response = await axios.post('/api/user/account/change-password', data)
    if (_.get(response, 'data.success')) {
      setPasswordMessage('Password changed.')
      window.setTimeout(() => {
        setPasswordMessage(null)
      }, 1200)
    }
  }

  if (!user.loaded) {
    return <LoadingContainer />
  }

  return (
    <div className="container mt-3">
      <div className="row mt-2">
        <div className="col-12 col-md-3">
          <div className="nav nav-pills mb-auto flex-column">
            {menuItems.map((item) => {
              if (item.hide) {
                return null
              }
              return (
                <span
                  key={`menu-${item.id}`}
                  onClick={() => {
                    setSelectedMenuItem(item.id)
                  }}
                  className={`selectable nav-link ${
                    selectedMenuItem === item.id ? 'active' : 'link-dark'
                  }`}
                >
                  {item.name}
                </span>
              )
            })}
          </div>
        </div>

        <div className="col-12 col-md-9">
          {selectedMenuItem === 'personal' && (
            <div className="row">
              <form onSubmit={handleSubmit} className="col-12 col-md-6">
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Email address
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={user.settings.email}
                    readOnly
                    disabled
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="input2" className="form-label">
                    Full Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="input2"
                    value={user.settings.full_name}
                    onChange={(e) => {
                      dispatch(changeSetting({ name: 'full_name', value: e.target.value }))
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="input3" className="form-label">
                    Nickname
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="input3"
                    value={user.settings.nickname}
                    onChange={(e) => {
                      dispatch(changeSetting({ name: 'nickname', value: e.target.value }))
                    }}
                  />
                </div>
                <div className="mb-3">
                  <input type="submit" className="btn btn-primary" value="Save Changes" />
                </div>
                <div className="message-box">{saveNotification && saveNotification}</div>
              </form>
            </div>
          )}
          {selectedMenuItem === 'password' && (
            <div className="row">
              {/* <h3 className="text-center">Change Password</h3> */}
              <form onSubmit={handleChangePasswordSubmit} className="w-50">
                <div className="mb-3">
                  <label htmlFor="oldPass" className="form-label">
                    Old Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="oldPass"
                    value={oldPassword}
                    onChange={(e) => {
                      setOldPassword(e.target.value)
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="newPass" className="form-label">
                    New Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="newPass"
                    value={newPassword}
                    onChange={(e) => {
                      setNewPassword(e.target.value)
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="repeatNewPass" className="form-label">
                    Repeat New Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="repeatNewPass"
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value)
                    }}
                  />
                </div>
                <div className="mb-3 mt-1">
                  <input type="submit" className="btn btn-primary" value="Change Password" />
                </div>
                <div className="message-box">{passwordMessage && passwordMessage}</div>
              </form>
            </div>
          )}
        </div>
      </div>
      {user.is_admin && (
        <div className="row mb-3">
          <hr />
          <h3>You are an admin</h3>
        </div>
      )}
    </div>
  )
}
