import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import config from '../config'
import WelcomeText from './WelcomeText'

const HowItWorks = ({ showModal, handleClose }) => {
  return (
    <Modal size="lg" show={showModal} onHide={handleClose}>
      <Modal.Body
        style={{
          padding: 0,
        }}
      >
        <div className="container bg-gaybor-dark-green p-3">
          <div className="row d-flex justify-content-center ">
            <div className="col-12 col-md-9 d-flex justify-content-center align-items-center text-white welcome-box p-4">
              <WelcomeText />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default HowItWorks
