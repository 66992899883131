import React, { useState } from 'react'
import axios from 'axios'
import { Modal } from 'react-bootstrap'
import config from '../config'
import { Link, redirect } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

const SignupPopup = ({ showModal, handleClose }) => {
  const navigate = useNavigate()

  const blankForm = {
    name: '',
    email: '',
    organization: '',
  }

  const [data, setData] = useState({ ...blankForm })

  const [formSent, setFormSent] = useState(false)
  const [error, setError] = useState(null)

  const handleSubmit = async (e) => {
    e.preventDefault()
    // get the current URL

    // set localStorage `pendingUrl` to the current URL
    // this will be used to redirect the user after signup
    localStorage.setItem('pendingUrl', window.location.href)

    try {
      const formData = {
        ...data,
      }
      const response = await axios.post('/api/campaign-viewer-signup', formData)
      setFormSent(true)
    } catch (e) {
      console.log(e)
      setError('Something went wrong. Please contact us')
    }
    // navigate('/search/post-signup')
  }

  return (
    <Modal size="xl" show={showModal} onHide={handleClose}>
      <Modal.Body
        style={{
          padding: 0,
        }}
      >
        <div className="container bg-gray-700 p-2">
          <div className="logo-container text-center mb-1">
            <img
              style={{
                maxWidth: '200px',
              }}
              src={config.gayborWhiteSquareImage}
              alt="Company Logo"
              className="company-logo"
            />
          </div>

          {formSent ? (
            <div className="text-white">
              <h2 className="text-center">Welcome to the Gayborhood!</h2>
              <div className="row d-flex justify-content-center ">
                <div className="col-12 col-md-9 d-flex justify-content-center align-items-center p-4">
                  <p className="fs-4">Please check your email for a link to continue</p>
                </div>
              </div>
            </div>
          ) : (
            <div className="row d-flex justify-content-center ">
              <div className="col-12 col-md-9 d-flex justify-content-center align-items-center text-white welcome-box p-2">
                <div className="fs-5">
                  <h2 className="text-center">Register to View Casting Sheet</h2>
                  <p>
                    To meet the curated roster of creators for your campaign, please sign up. This
                    verifies access for a secure experience in the marketplace.
                  </p>

                  <div className="d-flex justify-content-center align-items-center mb-3">
                    <form onSubmit={handleSubmit} className="w-50">
                      <div className="mb-3">
                        <label htmlFor="name" className="form-label">
                          Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          value={data.name}
                          onChange={(e) => setData({ ...data, name: e.target.value })}
                          required
                        />
                        <div className="invalid-feedback">Name is required.</div>
                      </div>

                      <div className="mb-3">
                        <label htmlFor="email" className="form-label">
                          Email Address
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          value={data.email}
                          onChange={(e) => setData({ ...data, email: e.target.value })}
                          required
                        />
                        <div className="invalid-feedback">Please enter a valid email.</div>
                      </div>
                      <div className="mb-3">
                        <label htmlFor="organization" className="form-label">
                          Brand/Agency
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="organization"
                          value={data.organization}
                          onChange={(e) => setData({ ...data, organization: e.target.value })}
                        />
                        <div className="invalid-feedback">Organization is required.</div>
                      </div>
                      <button type="submit" className="btn btn-light btn-lg">
                        Sign Up
                      </button>
                    </form>
                  </div>
                  {error && <div className="error">{error}</div>}

                  <hr />
                  <p className="my-2">
                    Already registered?{' '}
                    <Link
                      onClick={() => {
                        localStorage.setItem('pendingUrl', window.location.href)
                      }}
                      to="/search/request-login-link"
                    >
                      Sign In
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default SignupPopup
