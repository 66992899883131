import React from 'react'
import config from 'config'
import WelcomeText from 'components/WelcomeText'

const About = () => {
  return (
    <div className="container p-3">
      <div className="row d-flex justify-content-center ">
        <div className="col-12 col-md-9 d-flex justify-content-center align-items-center welcome-box p-4">
          <WelcomeText logoUrl={config.gayborDarkImgURL} />
        </div>
      </div>
    </div>
  )
}

export default About
