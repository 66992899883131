import { useEffect, useState } from 'react'
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/hooks'
import { useParams } from 'react-router-dom'
import _ from 'lodash'

import LoadingContainer from './components/LoadingContainer'
import PublicProfile from './components/PublicInfluencerProfile/index'
import { fetchPublicInfluencerProfile } from 'redux/influencerSlice'
import { RootState } from 'store'

export default function PublicProfileController() {
  const dispatch = useDispatch()
  const params = useParams<{ influencerId: string; teamId: string }>()
  const [error, setError] = useState('')

  useEffect(() => {
    dispatch(
      fetchPublicInfluencerProfile({
        influencer_id: params.influencerId!,
        team_id: params.teamId || '',
      }),
    )
  }, [params.influencerId, params.teamId])

  const influencerLoading = useSelector((state: RootState) => state.influencers.loading)

  const influencer = useSelector(
    (state: RootState) => state.influencers.entities[params.influencerId!],
  )
  const socials = useSelector((state: RootState) => state.socials.entities)
  const teamSettings = useSelector((state: RootState) => {
    return !_.isEmpty(state.influencers.teamSettings)
      ? state.influencers.teamSettings
      : state.teams.entities['gxpKNwP']?.settings
  })

  let selectedCampaign = useSelector((state: RootState) => {
    if (!state.campaigns.selectedCampaign) return null
    if (state.campaigns.selectedCampaign === 'favorites') return null
    return state.campaigns.entities[state.campaigns.selectedCampaign]
  })

  const favoriteInfluencerIds = useSelector((state: RootState) => state.user.favoriteInfluencers)

  if (!selectedCampaign) {
    selectedCampaign = { title: 'Favorites', id: 'favorites', influencers: favoriteInfluencerIds }
  }

  if (_.isEmpty(influencer) || influencerLoading === 'pending') {
    return <LoadingContainer />
  }

  return (
    <div>
      <div className="">
        <PublicProfile
          influencer={influencer}
          socials={socials}
          settings={{
            excluded_post_ids: [],
            ...teamSettings,
          }}
          openAccess={true}
          displaySettings={teamSettings}
        />
      </div>
    </div>
  )
}
