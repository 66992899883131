import React, { useState, useEffect } from 'react'
import { fetchStatus } from 'redux/userSlice'
import { fetchCampaigns } from 'redux/campaignsSlice'
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/hooks'
import { RootState } from 'store'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import './Table.scss'

import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
  ScrollRestoration,
  Outlet,
  Routes,
  Navigate,
} from 'react-router-dom'
import CustomNavbar from './CustomNavbar'
import Search from './Search'
import PublicProfileController from './PublicProfileController'
import ErrorPage from 'components/ErrorPage'
import Favorites from 'Favorites'
import LoginPage from 'LoginPage'
import ForgotPassword from 'ForgotPassword'
import LoadingContainer from './components/LoadingContainer'
import Conversations from 'Conversations'
import SignupForm from 'SignupForm'
import Partners from 'Partners'
import Campaigns from 'Campaigns'
import CampaignDetail from 'CampaignDetail'
import NewUserConfirm from 'NewUserConfirm'
import { fetchConversations } from 'redux/conversationSlice'
import { fetchTeams } from 'redux/teamsSlice'
import PublicTalentSheetRoster from 'components/PublicTalentSheetRoster'
import ContactForm from 'components/ContactForm'
import UserSettings from 'UserSettings'
import ErrorWrapper from 'ErrorPage'
import About from 'About'
import ResetPassword from 'components/ResetPassword'
import PostSignup from 'PostSignup'
import ConfirmCampaignAccount from 'ConfirmCampaignAccount'
import LoginLinkPage from 'LoginLinkPage'
import Terms from 'Terms'

function HomeRedirect() {
  return <Navigate to={'/search/'} />
}

const PublicRoute = ({ children }: { children: React.ReactNode }) => {
  const user = useSelector((state: RootState) => state.user)

  const isAuthed = user.loaded && user.authed

  return (
    <>
      <ScrollRestoration />
      {isAuthed && <CustomNavbar />}
      {children}
      <ToastContainer />
    </>
  )
}

const PrivateRoute = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch()

  const user = useSelector((state: RootState) => state.user)

  useEffect(() => {
    if (user.loaded && user.authed) {
      dispatch(fetchCampaigns({ page: 1, limit: 50 }))
      dispatch(fetchConversations())
      dispatch(fetchTeams())
    }
  }, [user.loaded, user.authed])

  if (!user.loaded) {
    return <LoadingContainer />
  }

  if (user.loaded && !user.authed) {
    return (
      <>
        <Navigate to="/search/login" />
      </>
    )
  }

  return (
    <>
      <ScrollRestoration />
      <CustomNavbar />
      {children}
      <ToastContainer />
    </>
  )
}

const ProtectedRoot = () => {
  const dispatch = useDispatch()

  const user = useSelector((state: RootState) => state.user)

  // useEffect(() => {
  //   if (user.loaded && user.authed) {
  //     dispatch(fetchCampaigns({ page: 1, limit: 50 }))
  //     dispatch(fetchConversations())
  //     dispatch(fetchTeams())
  //   }
  // }, [user.loaded, user.authed])

  if (!user.loaded) {
    return <LoadingContainer />
  }

  if (user.loaded && !user.authed) {
    return (
      <>
        <Navigate to="/search/login" />
      </>
    )
  }

  return (
    <>
      <ScrollRestoration />
      <CustomNavbar />
      <Outlet />
      <ToastContainer />
    </>
  )
}

const routesPrefix = '/search'

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<ErrorWrapper />}>
      <Route path={`${routesPrefix}/login`} element={<LoginPage />} />
      <Route path={`${routesPrefix}/request-login-link`} element={<LoginLinkPage />} />
      <Route path={`${routesPrefix}/forgot-password`} element={<ForgotPassword />} />
      <Route path={`${routesPrefix}/reset-password/:resetCode`} element={<ResetPassword />} />
      <Route
        path={`${routesPrefix}/new-user-confirm/:registrationCode`}
        element={<NewUserConfirm />}
      />
      <Route
        path={`${routesPrefix}/campaign-viewer-confirm/:registrationCode`}
        element={<ConfirmCampaignAccount />}
      />
      <Route
        path={`${routesPrefix}/campaign/share/:campaignId`}
        element={
          <PublicRoute>
            <PublicTalentSheetRoster />
          </PublicRoute>
        }
      />

      <Route
        path={`${routesPrefix}/influencers/:influencerId`}
        element={
          <PublicRoute>
            <PublicProfileController />
          </PublicRoute>
        }
      />

      <Route path={`${routesPrefix}/signup-form`} element={<SignupForm />} />
      <Route path="/" element={<ProtectedRoot />} errorElement={<ErrorPage />}>
        <Route path={`/`} element={<HomeRedirect />} />
        <Route path={`${routesPrefix}/team/:team_id`} element={<Search />} />
        <Route path={`${routesPrefix}`} element={<Search />} />
        <Route path={`${routesPrefix}/favorites`} element={<Favorites />} />
        <Route path={`${routesPrefix}/partners`} element={<Partners />} />
        <Route path={`${routesPrefix}/campaign/view/:campaignId`} element={<CampaignDetail />} />
        <Route path={`${routesPrefix}/campaigns`} element={<Campaigns />} />
        <Route path={`${routesPrefix}/messages`} element={<Conversations />} />
        <Route path={`${routesPrefix}/settings`} element={<UserSettings />} />
        <Route path={`${routesPrefix}/contact-tpp`} element={<ContactForm />} />
        <Route path={`${routesPrefix}/terms-and-conditions`} element={<Terms />} />
        <Route path={`${routesPrefix}/about`} element={<About />} />
      </Route>
    </Route>,
  ),
)

const Main = () => {
  const dispatch = useDispatch()
  const user = useSelector((state: RootState) => state.user)

  useEffect(() => {
    dispatch(fetchStatus())
  }, [])

  useEffect(() => {
    if (user.loaded && user.authed) {
      dispatch(fetchCampaigns({ page: 1, limit: 50 }))
      dispatch(fetchConversations())
      dispatch(fetchTeams())
    }
  }, [user.loaded, user.authed])

  return (
    <>
      <RouterProvider router={router} />
    </>
  )
}

export default Main
