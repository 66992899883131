import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { HiArrowLongRight } from 'react-icons/hi2'
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/hooks'
import { useParams } from 'react-router-dom'
import { selectedCampaignSelector } from 'redux/campaignsSlice'
import { selectUniqueResults } from './redux/searchSlice'
import SearchArea from './SearchArea'
import SearchResults from 'SearchResults'
import HowItWorks from 'components/HowItWorks'
import useLocalStorage from 'useLocalStorage'
import config from 'config'
import { joinClassNames } from 'utils'
import { performSearch } from './redux/searchSlice'
import _ from 'lodash'
import featuredInfluencers from './featuredInfluencers.json'
import { fetchPublicInfluencerProfile } from 'redux/influencerSlice'
import { Talent } from 'helpers/Talent'
import { addMarkdownLineBreaks } from 'utils'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const getDailyFeaturedInfluencerId = () => {
  const today = Math.floor(Date.now() / (1000 * 60 * 60 * 24))
  const featuredIndex = today % featuredInfluencers.length
  const featured = featuredInfluencers[featuredIndex]
  return featured
}

const Search = (): JSX.Element => {
  const dispatch = useDispatch()
  const urlParams = useParams()

  const [showHowItWorks, setShowHowItWorks] = useLocalStorage('showHowItWorks', true)

  const loadingState = useSelector((state) => state.search.loading)

  const user = useSelector((state) => state.user)

  // let selectedCampaign = useSelector((state) => {
  //   const selectedCampaignId = state.campaigns.selectedCampaign
  //   if (!selectedCampaignId) {
  //     return null
  //   }
  //   if (selectedCampaignId === 'favorites') {
  //     return { title: 'Favorites', id: 'favorites', influencers: state.user.favoriteInfluencers }
  //   }
  //   return state.campaigns.entities[selectedCampaignId]
  // })

  let selectedCampaign = useSelector(selectedCampaignSelector)

  const [showCampaignModal, setShowCampaignModal] = useState(false)

  const searchTeam = useSelector((state) => state.search.searchTeam)

  const total_count = useSelector((state) => state.search.total_count)
  const filtersApplied = useSelector((state) => state.search.filtersApplied)

  useEffect(() => {
    if (loadingState === 'initial' || (urlParams.team_id && urlParams.team_id !== searchTeam)) {
      dispatch(performSearch({ team_id: urlParams.team_id }))
    }
  }, [urlParams.team_id])

  const featuredInfluencerId = getDailyFeaturedInfluencerId()

  useEffect(() => {
    dispatch(fetchPublicInfluencerProfile({ influencer_id: featuredInfluencerId }))
  }, [])

  const searchResults = useSelector((state) => selectUniqueResults(state))

  const team = useSelector((state) =>
    urlParams.team_id ? state.teams.entities[urlParams.team_id] : null,
  )

  // const featured_agency = useSelector((state) => {
  //   const featuredTeam = state.teams.featuredTeam
  //   if (!featuredTeam) {
  //     return null
  //   }

  //   return state.teams.entities[featuredTeam]
  // })

  const filtersTouched = useSelector((state) => state.search.filtersTouched)
  const socials = useSelector((state) => state.socials.entities)

  const featuredInfluencer = useSelector(
    (state) => state.influencers.entities[featuredInfluencerId],
  )

  if (user.campaign_view_only) {
    return (
      <div className="container fs-4">
        <p>Your account is not authorized for search</p>
        <p>
          If this is an error or you would like to request access, please{' '}
          <Link className="text-black" to={'/search/contact-tpp'}>
            contact us.
          </Link>
        </p>
      </div>
    )
  }

  const defaultDescription = featuredInfluencer?.description || ''

  const descriptionText = featuredInfluencer?.custom_description
    ? addMarkdownLineBreaks(featuredInfluencer?.custom_description)
    : addMarkdownLineBreaks(defaultDescription)

  let featuredTalent, photoUrl
  if (featuredInfluencer) {
    featuredTalent = new Talent(featuredInfluencer, socials)
    photoUrl = featuredTalent.getPhotoUrl()
  }

  return (
    <div className={joinClassNames([`container-fluid pt-3`])}>
      <HowItWorks showModal={showHowItWorks} handleClose={() => setShowHowItWorks(false)} />
      <div className="row">
        <div className="col-2">
          <SearchArea />
        </div>
        <div className="col-10">
          <div className="container mb-3">
            {!filtersTouched && (
              <div className="row">
                <div className="col-md-6">
                  <div
                    style={{
                      borderRadius: '20px',
                      overflow: 'hidden',
                    }}
                    className="card featured-card shadow"
                  >
                    <div className="card-header bg-white text-primary-blue shadow">
                      <div className="d-flex justify-content-between">
                        <div className="fw-bold fs-5">Featured Talent</div>
                        <div
                          style={{
                            fontSize: '.9rem',
                          }}
                        >
                          {featuredInfluencer && (
                            <Link
                              style={{
                                textDecoration: 'none',
                                color: 'black',
                              }}
                              to={`${config.routesPrefix}/influencers/${featuredInfluencer.id}`}
                            >
                              View Profile{' '}
                              <span className="ps-2">
                                <HiArrowLongRight />
                              </span>
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="card-body p-2 bg-gray-100">
                      {featuredInfluencer && (
                        <div className="row">
                          <div className="col-md-6">
                            <div
                              className={joinClassNames([
                                `logo-container`,
                                'd-flex flex-column align-items-center',
                              ])}
                            >
                              <Link
                                to={`${config.routesPrefix}/influencers/${featuredInfluencer.id}`}
                              >
                                <img
                                  style={{
                                    maxHeight: '140px',
                                    maxWidth: '100%',
                                    borderRadius: '50%',
                                  }}
                                  className={joinClassNames(['portrait-rounded', 'mb-2'])}
                                  src={photoUrl}
                                  alt="Featured Talent"
                                />
                              </Link>
                            </div>
                          </div>
                          <div className="col-md-6 d-flex align-items-center justify-content-center">
                            <div className="d-flex flex-column justify-content-between">
                              <div className="fw-bold fs-5 text-center">
                                <Link
                                  style={{
                                    textDecoration: 'none',
                                    color: 'black',
                                  }}
                                  to={`${config.routesPrefix}/influencers/${featuredInfluencer.id}`}
                                >
                                  {featuredInfluencer.fullname}
                                </Link>
                              </div>
                              <div
                                style={{
                                  fontSize: '.8rem',
                                }}
                              >
                                <p className="text-center">{featuredInfluencer.tagline}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          {team && (
            <div className="container mb-3">
              <div className="row">
                <div className="col-2">
                  {team.image_url ? (
                    <img
                      style={{
                        maxHeight: '140px',
                      }}
                      src={team.image_url}
                      className={joinClassNames([
                        'img-fluid',
                        team?.settings?.displayDarkLogo ? 'bg-shine-black' : null,
                      ])}
                      alt="Team Image"
                    />
                  ) : (
                    <div className="text-center" style={{ fontSize: '1.7em' }}>
                      {team.team_name}
                    </div>
                  )}
                </div>
                <div className="col-6 d-flex align-items-center">
                  <p>{team.description}</p>
                </div>
              </div>
            </div>
          )}
          {filtersApplied && total_count && (
            <div className="container mb-3">
              <h3 className="text-end pe-3">
                {total_count} {'matching'} {total_count === 1 ? 'result' : 'results'}
              </h3>
            </div>
          )}
          {selectedCampaign && (
            <div className="container">
              <div className="row justify-content-end">
                <div className="col-3 d-flex justify-content-end">
                  <button
                    className="btn btn-outline-dark"
                    onClick={() => setShowCampaignModal(true)}
                  >
                    Adding to: {selectedCampaign.title}
                  </button>
                </div>
              </div>
            </div>
          )}

          <SearchResults
            results={searchResults}
            showCampaignModal={showCampaignModal}
            setShowCampaignModal={setShowCampaignModal}
          />
        </div>
      </div>
    </div>
  )
}

export default Search
