import React from 'react'
import config from '../config'

const WelcomeText = ({ logoUrl }) => {
  return (
    <div>
      <div className="logo-container text-center mb-4">
        <img
          style={{
            maxWidth: '240px',
          }}
          src={logoUrl || config.gayborWhiteSquareImage}
          alt="Company Logo"
          className="company-logo"
        />
      </div>
      <h1 className="my-3 text-center">Welcome to the Gayborhood!</h1>
      <div className="fs-5">
        <ul>
          <li className="my-3">
            When accessing the Gaybors Marketplace you have agreed that Gaybors Agency is the point
            of contact for the LGBTQ+ creators (aka gaybors) included in the platform. Gaybors have
            agreed to be in the marketplace and their rates listed, when available, are the same
            they provide to agencies and brands ensuring you don't overspend.
          </li>
          <li className="my-3">
            We invite you to{' '}
            <a
              style={{
                color: '#bd2258',
                padding: '0 2px',
              }}
              className="fw-bold"
              href="https://gayborsagency.com/marketplace/tour"
              target="_blank"
            >
              watch the tour
            </a>{' '}
            which shares how to navigate the Gaybors Marketplace when meeting gaybors, building
            campaigns and communicating directly with Gaybors Agency.{' '}
          </li>
          <li className="my-3">
            Watch for our monthly Gaybors Marketplace email which introduces the latest gaybors to
            join the platform and sponsorship opportunities.
          </li>
        </ul>
        <p className="my-3">
          If you have any questions and/or would like us to send an invite to a team member, please
          use the Contact Us form at the top of the page.
        </p>
      </div>
    </div>
  )
}

export default WelcomeText
