import colors from './styles/_variables.scss'

const age_groups = ['18-24', '25-34', '35-44', '45-64', '65-']

const categories = {
  Skincare: [
    'Hyperpigmentation',
    'Dry Skin',
    'Skincare routine',
    'Product reviews',
    'Beauty Over 40',
    'Bodycare',
    'Anti Aging',
    'Glowing Skin',
    'Dark Spots',
    'Sensative Skin',
    'body positive',
    'botox friendly',
    'clean beauty',
    'postpartum',
    'menopausal skin',
  ],
  Haircare: ['natural haircare', 'organic haircare', 'Hair tips'],
  'Account Type': ['Dermatologist', 'Medical Professional', 'Actress', 'Celebrity', 'Model'],
  Gender: ['Female', 'Male', 'Gender Neutral'],
  Ethnicity: ['African Descent', 'Asian', 'White / Caucasian', 'Hispanic American', 'Mixed'],
  Lifestyle: [
    'Fitness',
    'Travel',
    'Food',
    'Humor',
    'baby',
    'cooking',
    'diy',
    'luxury',
    'graphic designer',
    'health focused',
    'photographer',
    'vegan',
  ],
}

const moduleExports = {
  age_groups,
  categories,
  colors,
  SITE_ROOT: 'http://gaybor-search.lunaparkdigital.com',
  routesPrefix: '/search',
  gayborImgURL: 'https://tpp-content.us-east-1.linodeobjects.com/logos/gaybor_white_square.png',
  gayborDarkImgURL:
    'https://tpp-content.us-east-1.linodeobjects.com/logos/gaybor_black_square_sm.png',
  gayborHorizontalWhiteLogo:
    'https://tpp-content.us-east-1.linodeobjects.com/logos/gaybor_white_long.png',
  gayborWhiteSquareImage:
    'https://tpp-content.us-east-1.linodeobjects.com/logos/gaybor_white_square.png',
  preferredBackgroundClass: 'bg-gray-800',
  marketplaceSignupLink: 'https://gayborsagency.com/marketplace',
}

export default moduleExports
