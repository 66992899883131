import React from 'react'
import { Navbar, Nav, Dropdown, Button, Container } from 'react-bootstrap'
import { NavLink, useNavigate, NavLinkProps } from 'react-router-dom'
import { signOut } from 'redux/userSlice'
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/hooks'

import config from 'config'
import { joinClassNames } from 'utils'
import { IoMail, IoMailOutline } from 'react-icons/io5'
import { RootState } from 'store'

interface CustomNavLinkProps extends NavLinkProps {
  children: React.ReactNode
}

const CustomNavLink = ({ children, ...props }: CustomNavLinkProps) => {
  return (
    <NavLink {...props} end>
      {children}
    </NavLink>
  )
}

const CustomNavbar = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const user = useSelector((state: RootState) => state.user)
  const has_unread_conversation = useSelector(
    (state: RootState) => state.conversations.unread_conversations.length > 0,
  )

  if (user.loaded && (!user.authed || user.campaign_view_only)) {
    return null
  }

  const backgroundClass = `bg-gray-900`

  return (
    <Navbar className={joinClassNames([backgroundClass, 'py-3 shadow-sm'])} expand="lg">
      <Container fluid>
        <Navbar.Brand href="/search/" className="text-white">
          <img
            style={{ height: '80px' }}
            src={config.gayborHorizontalWhiteLogo}
            alt="Company Logo"
            className="company-logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-nav" />
        <Navbar.Collapse id="navbar-nav">
          <Nav className="text-uppercase text-white">
            <Nav.Link className="mx-3" as={CustomNavLink} to={`${config.routesPrefix}/`}>
              <span className="nav-link-text text-white">Search</span>
            </Nav.Link>

            <Nav.Link className="mx-3" as={CustomNavLink} to={`${config.routesPrefix}/favorites`}>
              <span className="nav-link-text text-white">Favorites</span>
            </Nav.Link>

            <Nav.Link className="mx-3" as={CustomNavLink} to={`${config.routesPrefix}/campaigns`}>
              <span className="nav-link-text text-white">Campaigns</span>
            </Nav.Link>
          </Nav>
          <Nav className="ms-auto">
            {/* <Nav.Link as={CustomNavLink} to={`${config.routesPrefix}/messages`}>
              <span className="me-3">
                {has_unread_conversation ? (
                  <IoMail color={config.colors['shine-gold']} size={30} />
                ) : (
                  <IoMailOutline color={config.colors['shine-gold']} size={30} />
                )}
              </span>
            </Nav.Link> */}
            <Dropdown align="end">
              <Dropdown.Toggle as={CustomToggle}>More</Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => navigate('/search/settings')}>
                  Settings
                </Dropdown.Item>
                <Dropdown.Item onClick={() => navigate('/search/contact-tpp')}>
                  Contact Us
                </Dropdown.Item>
                <Dropdown.Item onClick={() => navigate('/search/about')}>About</Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    navigate('/search/terms-and-conditions')
                  }}
                  className="text-decoration-none text-black"
                >
                  Terms and Conditions
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={() => {
                    dispatch(signOut()).then(() => {
                      navigate('/search/login')
                    })
                  }}
                >
                  Sign Out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

interface CustomToggleProps {
  children: React.ReactNode
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const CustomToggle = React.forwardRef<HTMLButtonElement, CustomToggleProps>(
  ({ children, onClick }, ref) => (
    <Button
      ref={ref}
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        onClick(e)
      }}
      style={{ borderColor: 'black' }}
      variant="outline-primary"
      size="lg"
      className="rounded"
    >
      {children}
      <i className="bi bi-caret-down-fill ml-1"></i>
    </Button>
  ),
)

export default CustomNavbar
