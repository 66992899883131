import axios from 'axios'
import { useState, useEffect } from 'react'
import LoginContainer from 'components/LoginContainer'
import { useNavigate, useParams } from 'react-router-dom'
import { setUser } from 'redux/userSlice'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

function ConfirmCampaignAccount() {
  const [error, setError] = useState(null)
  const dispatch = useDispatch()

  const [showRenewLink, setShowRenewLink] = useState(false)

  const navigate = useNavigate()
  const params = useParams()

  const confirmAccount = () => {
    // get registration code from url
    const code = params.registrationCode

    // send request to confirm account
    axios
      .post('/api/user/follow-magic-link', {
        token: code,
      })
      .then(({ data }) => {
        console.log(data)

        const pendingUrl = localStorage.getItem('pendingUrl')

        if (data.authed) {
          dispatch(setUser(data))
          if (pendingUrl) {
            console.log('redirecting to', pendingUrl)
            localStorage.removeItem('pendingUrl')
            window.location.href = pendingUrl
          } else {
            setError(
              'Something went wrong while trying to redirect you back to the campaign. Please try loading the campaign link again with the same web browser you are currently using.',
            )
          }
        } else {
          setError('Something went wrong while trying to find your account.')
        }
        // set user in redux
        // navigate to dashboard
      })
      .catch(() => {
        setShowRenewLink(true)
        setError(
          'Something went wrong while trying to find your account. The link you clicked may have expired. Please try again.',
        )
      })
  }

  useEffect(() => {
    confirmAccount()
  }, [])

  return (
    <LoginContainer>
      <h2 className="mb-3 text-center">Welcome</h2>
      <div className="row justify-content-center">
        <p>
          Sending you to <a href={localStorage.getItem('pendingUrl') || '#'}>your campaign</a>
        </p>
        <div className="col-10 col-sm-6">{error && <div className="error">{error}</div>}</div>
        {showRenewLink && (
          <div>
            <p>
              You can request a new sign-in link{' '}
              <Link to={'/search/request-login-link'}>here</Link>
            </p>
          </div>
        )}
      </div>
    </LoginContainer>
  )
}

export default ConfirmCampaignAccount
