import React, { useState } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { setUser } from './redux/userSlice'
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/hooks'
import axios from 'axios'
import config from './config'

interface LoginPageProps {
  backgroundClass?: string
  logoImage?: string
}

const LoginPage: React.FC<LoginPageProps> = ({
  logoImage = config.gayborWhiteSquareImage,
  backgroundClass = 'bg-gray-800',
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState<string | null>(null)

  const user = useSelector((state) => state.user)

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    try {
      const { data } = await axios.post('/api/user/account/signin', {
        email,
        password,
      })
      dispatch(setUser(data))
      // if user has a pending URL saved in local storage, redirect to that URL
      const pendingUrl = localStorage.getItem('pendingUrl')
      if (pendingUrl) {
        localStorage.removeItem('pendingUrl')
        navigate(pendingUrl)
        return
      }

      navigate('/search')
    } catch (e) {
      console.log(e)
      setError('Bad sign-in information')
    }
  }

  if (user.loaded && user.authed) {
    return <Navigate to="/search" />
  }

  return (
    <div className={`container-fluid login ${backgroundClass}`}>
      <div
        style={{
          minWidth: '65%',
        }}
        className="row"
      >
        <div
          className={`col-12 col-md-5 d-flex align-items-center ${backgroundClass} text-white welcome-box`}
        >
          <div style={{ paddingRight: '10%' }}>
            <div className="logo-container text-center mb-4">
              <img src={logoImage} alt="Company Logo" className="company-logo" />
            </div>
            {/* <h1>Welcome!</h1> */}
            <p
              style={{
                fontSize: '1.2rem',
              }}
            ></p>
          </div>
        </div>
        <div className="col-12 col-md-7 d-flex align-items-center bg-white p-5">
          <div className="w-100">
            <h2 className="mb-3">Login</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  autoFocus
                  value={email}
                  placeholder="email"
                  onChange={(e) => {
                    setEmail(e.target.value)
                  }}
                  style={{
                    maxWidth: '400px',
                  }}
                />
              </div>
              <div className="mb-3">
                <input
                  type="password"
                  className="form-control"
                  placeholder="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value)
                  }}
                  style={{
                    maxWidth: '400px',
                  }}
                />
              </div>
              <div className="d-flex">
                <input className="btn sign-in-btn my-3" type="submit" value="Sign In" />
              </div>
              <div className="mt-4">
                <p>
                  Forgot your password? click <Link to="/search/forgot-password">here</Link>
                </p>
              </div>
              <div className="mt-4">
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginPage
