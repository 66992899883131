import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import _ from 'lodash'
import axios from 'axios'
import { RootState } from 'store'

interface FetchSettingsResponse {
  data: any // Update with the actual type of the response data
}

interface ToggleFavoriteInfluencerParams {
  influencer_id: string
}

interface ToggleFavoriteInfluencerResponse {
  influencer_id: string
  state: string
}

interface UserState {
  account_id: string
  authed: boolean
  id: null | string
  loaded: boolean
  teams: any[] // Update with the actual type of teams
  settings: Record<string, string | boolean> // Update with the actual type of settings
  teamSettings: Record<string, string | boolean> // Update with the actual type of teamSettings
  favoriteInfluencers: string[]
  campaign_view_only?: boolean
}

interface StatusPayload {
  user_id: string
  authed: boolean
  is_admin: boolean
  is_staff: boolean
  teams: any[] // Update with the actual type of teams
  teamSettings?: any // Update with the actual type of teamSettings
  teamRole?: string
  abilities?: any // Update with the actual type of abilities
}

export const fetchStatus = createAsyncThunk('user/getStatus', async () => {
  const response = await axios.get('/api/status')

  return response.data
})

export const signOut = createAsyncThunk('user/signOut', async () => {
  const response = await axios.get('/api/user/signout')

  return response.data
})

export const fetchSettings = createAsyncThunk('user/fetchSettings', async () => {
  const response = await axios.get('/api/user/configuration')

  return response.data
})


export const fetchFavoriteInfluencers = createAsyncThunk(
  'user/fetchFavoriteInfluencers',
  async () => {
    const response = await axios.get('/api/directory/influencers/favorites')
    return response.data
  },
)

export const toggleFavoriteInfluencer = createAsyncThunk(
  'user/toggleFavoriteInfluencer',
  async (
    { influencer_id }: ToggleFavoriteInfluencerParams,
    thunkAPI,
  ): Promise<ToggleFavoriteInfluencerResponse> => {
    const state: RootState = thunkAPI.getState() as RootState

    const favorites = state.user.favoriteInfluencers

    const newState = favorites.includes(influencer_id) ? 'absent' : 'present'

    const response = await axios.post('/api/directory/influencers/favorites', {
      influencer_ids: [influencer_id],
      state: newState,
    })

    return { influencer_id, state: newState }
  },
)


const initialState: UserState = {
  account_id: '',
  authed: false,
  id: null,
  loaded: false,
  teams: [],
  settings: {},
  teamSettings: {},
  favoriteInfluencers: [],
}

const processStatusPayload = (payload: StatusPayload) => {
  return {
    ...payload,
    id: payload.user_id,
    account_id: payload.user_id,
    authed: payload.authed,
    is_admin: payload.is_admin,
    is_staff: payload.is_staff,
    teams: payload.teams,
    primary_team_id: _.get(payload, 'teams[0].id'),
    teamSettings: payload.teamSettings || {},
    teamRole: payload.teamRole || '',
    abilities: payload.abilities || {},
  }
}


const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, { payload }: PayloadAction<StatusPayload>) => {
      return {
        ...state,
        ...processStatusPayload(payload),
        settings: state.settings,
        loaded: true,
      }
    },
    setTeams: (state, { payload }: PayloadAction<any[]>) => {
      state.teams = payload
    },
    changeSetting: (state, { payload }: PayloadAction<{ name: string; value: any }>) => {
      state.settings[payload.name] = payload.value
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSettings.fulfilled, (state, { payload }) => {
        state.settings = payload.user
        state.loaded = true
      })
      .addCase(fetchStatus.fulfilled, (state, { payload }) => {
        return {
          ...state,
          ...processStatusPayload(payload),
          settings: state.settings,
          loaded: true,
        }
      })
      .addCase(signOut.fulfilled, (state, { payload }) => {
        return {
          ...initialState,
        }
      })
      .addCase(fetchFavoriteInfluencers.fulfilled, (state, { payload }) => {
        state.favoriteInfluencers = payload.favoriteInfluencers
      })
      .addCase(toggleFavoriteInfluencer.fulfilled, (state, { payload }) => {
        if (payload.state === 'present') {
          state.favoriteInfluencers.push(payload.influencer_id)
        } else {
          state.favoriteInfluencers = state.favoriteInfluencers.filter(
            (id) => id !== payload.influencer_id,
          )
        }
      })
  },
})

export const { setUser, setTeams, changeSetting } = userSlice.actions

export default userSlice.reducer
