import React, { useState } from 'react'
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/hooks'

import { Form, Button, Modal } from 'react-bootstrap'
import axios from 'axios'
import { fetchConversations, updateNewConversation } from 'redux/conversationSlice'
import { BsFillSendFill } from 'react-icons/bs'
import { useParams } from 'react-router-dom'

const placeHolderText = `Hi,

I am reaching out on behalf of [Your Brand Name]. We are interested in exploring the possibility of a collaboration on our upcoming social media campaign.

Let's talk about availability, preferred platforms for partnerships, and any sponsorship packages or rates applicable for collaboration.

Best regards,
[Your Name]
[Your Position]`

const NewConversationModal = ({ show, handleClose }) => {
  const dispatch = useDispatch()
  const params = useParams()

  const setIsFormSubmitted = (value) => {
    dispatch(updateNewConversation({ submitted: value }))
  }

  const isFormSubmitted = useSelector((state) => state.conversations.newConversation.submitted)

  const newConversation = useSelector((state) => state.conversations.newConversation)

  const handleSubmit = async (event) => {
    event.preventDefault()
    setIsFormSubmitted(true)

    try {
      await axios.post('/api/conversations/create/influencer-request', {
        influencer_id: params.influencerId,
        content: newConversation.body,
        title: 'Influencer Request',
        sendEmail: true,
      })
      return
    } catch (e) {
      console.error(e)
    }
  }

  const resetForm = () => {
    dispatch(updateNewConversation({ body: '' }))
    // setIsFormSubmitted(false)
    handleClose()
  }

  return (
    <Modal size="lg" show={show} onHide={resetForm}>
      <Modal.Body>
        <div
          // style={{
          //   minHeight: '40vh',
          // }}
          className="container mt-3"
        >
          {isFormSubmitted ? (
            <div className="d-flex flex-column justify-content-between">
              <h4>Success!</h4>
              <p>
                Your message has been successfully sent. A Gaybors Agency team member will be in
                touch shortly.
              </p>
              <Button
                style={{
                  width: '100px',
                }}
                variant="primary"
                onClick={resetForm}
              >
                OK
              </Button>
            </div>
          ) : (
            <>
              <h2 className="mb-4">Start a Conversation</h2>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Control
                    as="textarea"
                    rows={12}
                    value={newConversation.body}
                    onChange={(e) => dispatch(updateNewConversation({ body: e.target.value }))}
                    placeholder={placeHolderText}
                  />
                </Form.Group>
                <Button className="mb-2" variant="primary" type="submit">
                  Send Message <BsFillSendFill className="ms-2" />
                </Button>
              </Form>
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default NewConversationModal
