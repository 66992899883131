import axios from 'axios'
import { useState } from 'react'
import LoginContainer from 'components/LoginContainer'

function ForgotPassword() {
  const [email, setEmail] = useState('')
  const [error, setError] = useState(null)

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const { data } = await axios.post('/api/user/account/forgot-password', {
        email,
      })
      setError('A password reset link has been sent to the email you provided')
    } catch (e) {
      console.log(e)
      setError('Something went wrong. Please contact us about recovering your account.')
    }
  }

  return (
    <LoginContainer>
      <h2 className="mb-3 text-center">Forgot Password</h2>
      <p className="mb-5 text-center">Enter your email</p>
      <div className="row justify-content-center">
        <div className="col-10 col-sm-6">
          <form className="sign-in-form" onSubmit={handleSubmit}>
            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                autoFocus
                value={email}
                placeholder="email"
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
              />
            </div>
            <div className="d-flex justify-content-center">
              <input className="btn btn-dark my-3" type="submit" value="Send Reset Link" />
            </div>
          </form>
          {error && <div className="error">{error}</div>}
        </div>
      </div>
    </LoginContainer>
  )
}

export default ForgotPassword
