import { useState, useEffect, useRef } from 'react'
import { joinClassNames } from 'utils'
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/hooks'
import { Link } from 'react-router-dom'
import config from './config'
import { RootState } from 'store'

import './styles/Partners.scss'
import { EntityId } from '@reduxjs/toolkit'

const Partners = () => {
  const dispatch = useDispatch()

  const teams = useSelector((state: RootState) => state.teams.entities)
  const team_ids = useSelector((state: RootState) => state.teams.ids)

  return (
    <div className="container-fluid m-0 pt-3 bg-shine-black">
      <div className="container">
        <div className="row row-eq-height">
          {team_ids.map((team_id: EntityId, index: number) => {
            const team = teams[team_id]

            if (!team) {
              return null
            }

            const { image_url, description } = team
            return (
              <div
                key={`th-${index}`}
                className={joinClassNames([
                  `col-12 col-md-4 text-center`,
                  'my-3',
                  'p-4',
                  'justify-content-center',
                  'partner-holder',
                ])}
              >
                <div className="d-flex flex-column justify-content-center align-items-center shadow p-3 partner-holder-content bg-platinum">
                  <div
                    className={joinClassNames([
                      image_url ? `logo-container`: null,
                      // 'd-flex flex-column align-items-center',
                    ])}
                  >
                    <Link
                      style={{
                        textDecoration: 'none',
                      }}
                      to={`${config.routesPrefix}/team/${team_id}`}
                    >
                      {image_url ? (
                        <img
                          className={joinClassNames([
                            'logo-border',
                            team?.settings?.displayDarkLogo ? 'bg-shine-black' : null,
                          ])}
                          src={image_url}
                        />
                      ) : (
                        <h2
                          className="text-black py-3"
                          style={{
                            fontSize: '3rem',
                          }}
                        >
                          {team?.team_name}
                        </h2>
                      )}
                    </Link>
                  </div>
                  <p>{description}</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Partners
