import { useRouteError } from 'react-router-dom'

interface ErrorPageProps {
  children?: React.ReactNode;
}

export default function ErrorPage({ children }: ErrorPageProps) {
  const error = useRouteError()
  console.error(error)

  return (
    <div className="container mt-3" role="alert">
      <h2 className="my-3">Gaybors Marketplace</h2>
      <div>
        <h3>Oops! Something went wrong.</h3>
        <p>An error occurred while trying to load the page.</p>
        <p>Please try again later or contact our support team for assistance.</p>
      </div>
      {children}
    </div>
  )
}
