import normalMillify from 'millify'
import { Slide, toast } from 'react-toastify'
import Color from 'color'
import config from './config'

export const shortenText = (text: string, maxLength = 100) => {
  if (text.length > maxLength) {
    return text.slice(0, maxLength - 3) + '…'
  } else {
    return text
  }
}

export const safelyMillify = (num: number | undefined) => {
  if (!num) {
    return ''
  }
  return normalMillify(num)
}

export const addMarkdownLineBreaks = (text: string) => {
  return text
    .split('\n')
    .map((line) => line + '  \n')
    .join('')
}

/**
 * Joins an array of class names or any number of class name strings into a single string, with each class name separated by a space.
 *
 * @param classNames - An array of class names, which can include null or undefined values.
 * @returns A string containing all the class names joined together with a space delimiter.
 */
export const joinClassNames = (classNames: (string | null | undefined)[]): string => {
  if (!classNames || !classNames.length) {
    return ''
  }
  return classNames.filter((className) => !!className).join(' ')
}

export const getSocialName = (platform: string) => {
  if (platform === 'youtube') return 'YouTube'
  if (platform === 'tiktok') return 'TikTok'
  if (platform === 'instagram') return 'Instagram'
  return platform
}

export function removeEmails(str: string) {
  var emailRegex = /[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}/g
  return str.replace(emailRegex, '')
}

export function showToast({ customText = 'Team Settings Saved' }) {
  toast.success(customText, {
    transition: Slide,
    position: 'top-center',
    autoClose: 1500,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    theme: 'light',
  })
}

function parseRgbString(rgbString: string) {
  const rgbValues = rgbString.match(/\d+/g)
  if (!rgbValues) {
    throw new Error('Invalid rgb string')
  }
  return rgbValues.map(Number)
}

export function getContrastColor(rgb: string) {
  const [r, g, b] = parseRgbString(rgb)
  const brightness = (r * 299 + g * 587 + b * 114) / 1000
  return brightness > 128 ? 'black' : 'white'
}

export function getContrastingTextForBackground(backgroundColor = 'white') {
  try {
    backgroundColor = config.colors[backgroundColor] || backgroundColor
    const rgbBgColor = Color(backgroundColor).rgb().string()
    const contrastColor = getContrastColor(rgbBgColor)
    return contrastColor
  } catch (e) {
    console.log('Error getting contrasting text color for background color', e)
    return 'black'
  }
}
