import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  createSelector,
  PayloadAction,
} from '@reduxjs/toolkit'
import _ from 'lodash'
import { performSearch, performUpdate } from './searchSlice'
import { fetchPublicInfluencerProfile, fetchSingleInfluencer } from './influencerSlice'
import { fetchFavoriteInfluencers } from './userSlice'
import { fetchCampaign } from './campaignsSlice'
import { RootState } from 'store'
import { Social } from 'types'

const socialsAdapter = createEntityAdapter<Social>()

export const socialSlice = createSlice({
  name: 'socials',
  initialState: socialsAdapter.getInitialState({
    loading: 'idle',
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(performSearch.fulfilled, (state, { payload }) => {
        socialsAdapter.upsertMany(state, payload.socials)
      })
      .addCase(performUpdate.fulfilled, (state, { payload }) => {
        socialsAdapter.upsertMany(state, payload.socials)
      })
      .addCase(
        fetchSingleInfluencer.fulfilled,
        (state, { payload }: PayloadAction<{ error: boolean; socials: Social[] }>) => {
          if (!payload.error) {
            socialsAdapter.upsertMany(state, payload.socials)
          }
          state.loading = 'idle'
        },
      )
      .addCase(fetchPublicInfluencerProfile.fulfilled, (state, { payload }) => {
        socialsAdapter.upsertMany(state, payload.socials)
      })
      .addCase(fetchFavoriteInfluencers.fulfilled, (state, { payload }) => {
        socialsAdapter.upsertMany(state, payload.socials)
      })
      .addCase(fetchCampaign.fulfilled, (state, { payload }) => {
        socialsAdapter.upsertMany(state, payload.socials)
      })
  },
})

// selector
const selectSocials = (state: RootState) => state.socials.entities

// reselect function
export const selectSocialsMemoized = createSelector([selectSocials], (socials) => socials)

// Can create a set of memoized selectors based on the location of this entity state
export const socialsSelector = socialsAdapter.getSelectors((state: RootState) => state.socials)

export default socialSlice.reducer
