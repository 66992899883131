import _ from 'lodash'
import { getContrastColor, joinClassNames, getContrastingTextForBackground } from 'utils'
import { useEffect, useRef, useState, useMemo } from 'react'
import config from 'config'

export default function TalentSheetBranding({ talentSheet, displaySettings = {} }) {
  const titleFont = _.get(talentSheet, 'settings.displayRosterTitleFont') || 'barlow'

  const darkMode = displaySettings.displayDarkMode

  // `displayDarkLogo` says that the logo is already dark, so we don't need to invert it
  const imageShouldInvert = darkMode && !displaySettings.displayDarkLogo

  const mainContainerRef = useRef(null)
  const [textColorClass, setTextColorClass] = useState('text-black')

  const backgroundColorClass = useMemo(() => {
    let backgroundClass = displaySettings.displayProfileBackground
      ? `bg-${displaySettings.displayProfileBackground}`
      : `bg-gray-900`
    return backgroundClass
  }, [displaySettings])

  useEffect(() => {
    const colorSetting = displaySettings?.displayRosterBackgroundColor || 'white'
    const contrastingTextColor = getContrastingTextForBackground(colorSetting)
    setTextColorClass(`text-${contrastingTextColor}`)
  }, [displaySettings.displayRosterBackgroundColor])

  return (
    <div
      ref={mainContainerRef}
      className={joinClassNames([
        `container-fluid`,
        backgroundColorClass,
        `bottom-drop-shadow`,
        darkMode ? 'dark-mode' : null,
      ])}
    >
      <div className="row py-2">
        <div className="col-12 col-md-4">
          <div className="team-logo-box d-flex justify-content-center justify-content-md-start px-3">
            <div className="team-logo-container">
              <a target="_blank" href={config.marketplaceSignupLink}>
                <img
                  className={joinClassNames([`profile-team-logo`])}
                  src={config.gayborHorizontalWhiteLogo}
                />
                {/* <div className="d-flex flex-column justify-content-center">
                  <span className="btn btn-link text-white">
                    Join
                  </span>
                </div> */}
              </a>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-4">
          <h2
            className={joinClassNames([
              `sheet-header-text font-${titleFont} text-center p-2`,
              textColorClass,
            ])}
          >
            {!_.isEmpty(talentSheet.title) ? talentSheet.title : 'Potential Campaign Talent'}
          </h2>
        </div>
      </div>
    </div>
  )
}
