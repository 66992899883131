import _, { map } from 'lodash'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useRef, useState, useMemo } from 'react'

import LoadingContainer from 'components/LoadingContainer'
import { Rings } from 'react-loader-spinner'
import TalentSheetBranding from './TalentSheetBranding'
import { getContrastColor, joinClassNames, getContrastingTextForBackground } from 'utils'
import { useInView } from 'react-intersection-observer'
import { Talent } from 'helpers/Talent'
// import mixpanel from './mixpanelBrowser'
import { sendEvent } from 'analytics'
import { useSelector } from 'react-redux'
import SignupPopup from './SignupPopup'

const RenderPublicRoster = ({
  talentSheet,
  socials,
  displayedInfluencers,
  RosterItem,
  totalPages = 1,
  currentGroup,
  pageLimit,
  talentLoading,
  setTalentLoading,
  sheetIsLoading,
  loadNextGroup,
  source = 'Talent Sheet',
  displaySettings = {
    displayAccentColor: 'shine-gold',
    displayRosterBackgroundColor: 'gray-900',
  },
}) => {
  const params = useParams()
  const navigate = useNavigate()
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
    onChange: (inView, entry) => {
      if (inView && !talentLoading) {
        setTalentLoading(true)
        loadNextGroup()
      }
    },
    initialInView: true,
  })

  const mainContainerRef = useRef(null)
  const [textColorClass, setTextColorClass] = useState('text-white')

  let backgroundColorClass = useMemo(() => {
    return displaySettings.displayRosterBackgroundColor
      ? `bg-${displaySettings.displayRosterBackgroundColor}`
      : `custom-bg-${displaySettings.displayAccentColor}-light`
  }, [displaySettings])

  useEffect(() => {
    const colorSetting = displaySettings?.displayRosterBackgroundColor || 'white'
    const contrastingTextColor = getContrastingTextForBackground(colorSetting)
    setTextColorClass(`text-${contrastingTextColor}`)
  }, [displaySettings.displayRosterBackgroundColor])

  const unauthedUser = useSelector((state) => state.user.loaded && !state.user.authed)

  return (
    <>
      <SignupPopup showModal={unauthedUser} handleClose={() => {}} />
      <TalentSheetBranding displaySettings={displaySettings} talentSheet={talentSheet} />
      <div
        ref={mainContainerRef}
        className={joinClassNames([backgroundColorClass, 'public-roster-container'])}
      >
        <div className="container">
          <h4 className={joinClassNames([`text-center`, textColorClass, `mb-3 py-3`])}>
            Click creator photo for analytics
          </h4>
          <div className={joinClassNames([`row gx-5`])}>
            {_.isEmpty(displayedInfluencers) && (
              <div className="d-flex justify-content-center">
                <Rings
                  height="80"
                  width="80"
                  color="#5863f8ff"
                  radius="6"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="rings-loading"
                />
              </div>
            )}
            {map(displayedInfluencers, (influencer, i) => {
              const talent = new Talent(influencer, socials)
              const profile = {
                ...influencer,
              }
              profile.socials = talent.getSocials().allSocials

              return (
                <RosterItem
                  key={`influencer-${influencer.id}`}
                  specialText={_.get(talentSheet, ['notes', influencer.id], null)}
                  influencer={influencer}
                  handleClick={() => {
                    // mixpanel.track(`${source} - Clicked Influencer`, {
                    //   influencer_id: influencer.id,
                    //   talent_sheet_id: params.talentSheetId,
                    // })
                    sendEvent('sheet_click_influencer', {
                      influencer_id: influencer.id,
                      sheet_id: params.talentSheetId,
                    })
                    window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
                    navigate(`/search/influencers/${influencer.id}`)
                  }}
                  profile={profile}
                  displaySettings={_.get(talentSheet, 'settings', {})}
                  source={source}
                />
              )
            })}
          </div>
        </div>
        {totalPages > 1 && currentGroup * pageLimit <= talentSheet?.influencer_ids?.length && (
          <div ref={ref} className="d-flex justify-content-center w-100 pb-4">
            <button className="btn btn-primary" onClick={loadNextGroup}>
              {talentLoading ? 'Loading...' : 'Load More'}
            </button>
          </div>
        )}
        {sheetIsLoading && <LoadingContainer />}
      </div>
    </>
  )
}

export default RenderPublicRoster
