import { Fragment, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  fetchCampaigns,
  resetCampaign,
  updateNewCampaign,
  createCampaign,
  deleteCampaign,
} from 'redux/campaignsSlice'
// import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/hooks'
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/hooks'

import Button from 'react-bootstrap/Button'
import { FaTrash } from 'react-icons/fa'
import Modal from 'react-bootstrap/Modal'
import PageMenu from './components/PageMenu'
import _ from 'lodash'
import axios from 'axios'
import useLocalStorage from 'hooks/useLocalStorage'
import LoadingContainer from 'components/LoadingContainer'
import config from './config'

function formatDate(dateString) {
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  let dateObj = new Date(dateString)
  let month = monthNames[dateObj.getUTCMonth()] // months from 1-12
  let day = dateObj.getUTCDate()
  let year = dateObj.getUTCFullYear()

  return month + ' ' + day + ' ' + year
}

function ConfirmModal({ show, handleClose }) {
  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Delete?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Do you really want to delete this campaign? There is no 'Undo' button for this.
        </Modal.Body>
        <Modal.Footer>
          <Button
            data-purpose="cancel-delete"
            variant="secondary"
            onClick={() => {
              handleClose(false)
            }}
          >
            Cancel
          </Button>
          <Button
            data-purpose="confirm-delete"
            onClick={() => {
              handleClose(true)
            }}
            variant="primary"
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const RenderCampaignTile = ({ campaign }) => {
  return (
    <div className="col-12" key={`campaign-${campaign.id}`} data-type="campaign-table-row">
      <div className="container list-group">
        <div className="justify-content-between list-group-item list-group-item-action">
          <Link
            className="text-decoration-none text-black"
            to={`${config.routesPrefix}/campaign/view/${campaign.id}`}
          >
            <div className="row">
              <div className="col-3">
                <p className="">{campaign.title}</p>
              </div>
              <div className="col-3">Created {formatDate(campaign.created_at)}</div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export const CreateCampaignForm = ({ handleClose }) => {
  const dispatch = useDispatch()
  const newCampaign = useSelector((state) => state.campaigns.newCampaign)

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
      }}
    >
      <div className="mb-3">
        <label htmlFor="campaign-title" className="form-label">
          Campaign Title
        </label>
        <input
          type="text"
          className="form-control"
          id="campaign-title"
          aria-describedby="campaign-title"
          value={newCampaign.title}
          onChange={(e) => {
            dispatch(updateNewCampaign({ title: e.target.value }))
          }}
        />
      </div>
    </form>
  )
}

function CreateCampaignModal({ show, handleClose }) {
  const newCampaign = useSelector((state) => state.campaigns.newCampaign)
  const dispatch = useDispatch()

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Campaign</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <form
              onSubmit={(e) => {
                e.preventDefault()
              }}
            >
              <div className="mb-3">
                <label htmlFor="campaign-title" className="form-label">
                  Campaign Title
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="campaign-title"
                  aria-describedby="campaign-title"
                  value={newCampaign.title}
                  onChange={(e) => {
                    dispatch(updateNewCampaign({ title: e.target.value }))
                  }}
                />
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            data-purpose="cancel-create"
            variant="secondary"
            onClick={() => {
              handleClose(false)
            }}
          >
            Cancel
          </Button>
          <Button
            data-purpose="confirm-create"
            onClick={() => {
              handleClose(true)
            }}
            variant="primary"
          >
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default function Campaigns() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showCreateModal, setShowCreateModal] = useState(false)

  const showPageMenu = false

  const [sheetToDelete, setCampaignToDelete] = useState(null)
  const [searchFilter, setFilter] = useState('')
  const [sheetTitleFilterInput, setSheetTitleFilterInput] = useState('')

  const [showFullInfluencerList, setShowFullInfluencerList] = useState({})

  const searchParams = new URLSearchParams(window.location.search)
  const page = Number(searchParams.get('page')) || 1

  const pageBaseUrl = '/campaigns'
  const pageLimit = 50

  const user = useSelector((state) => state.user)
  const influencers = useSelector((state) => state.influencers.entities)
  const campaigns = useSelector((state) => state.campaigns.entities)

  const newCampaign = useSelector((state) => state.campaigns.newCampaign)

  const handleCloseDeleteModal = async (confirm) => {
    setShowDeleteModal(false)
    console.log('want to delete?', confirm)
    if (confirm) {
      dispatch(deleteCampaign({ id: sheetToDelete })).then(() => {
        dispatch(fetchCampaigns({ page, limit: pageLimit }))
      })
    }
    return
  }

  useEffect(() => {
    dispatch(fetchCampaigns({ page, limit: pageLimit }))
  }, [dispatch])

  const tableColumns = [
    {
      th: (
        <th style={{ width: '40%' }} className="text-center" scope="col">
          Title
        </th>
      ),
      td: (campaign, settings = {}) => {
        return (
          <td>
            <Link
              className={`text-black fs-5`}
              to={`${config.routesPrefix}/campaign/view/${campaign.id}`}
            >
              {campaign.title}
            </Link>
          </td>
        )
      },
    },
    // {
    //   th: (
    //     <th className="text-center" scope="col">
    //       Talent
    //     </th>
    //   ),
    //   td: (campaign) => {
    //     let sheetInfluencers = _.get(campaign, 'influencers', []).map((id) => {
    //       return influencers[id] || {}
    //     })
    //     return (
    //       <td>
    //         <ul>
    //           {sheetInfluencers.map((inf, i) => {
    //             return <li key={`influencer-${inf.id}`}>{inf.fullname}</li>
    //           })}
    //         </ul>
    //       </td>
    //     )
    //   },
    // },
    {
      th: (
        <th className="text-center" scope="col">
          Created
        </th>
      ),
      td: (campaign) => {
        return <td className="text-center">{formatDate(campaign.created_at)}</td>
      },
    },
    {
      th: (
        <th className="text-center" scope="col">
          Delete
        </th>
      ),
      td: (campaign) => {
        return (
          <td
            onClick={() => {
              setCampaignToDelete(campaign.id)
              setShowDeleteModal(true)
            }}
            className="ps-4 selectable text-center"
            data-sheet-id={campaign.id}
          >
            <FaTrash />
          </td>
        )
      },
    },
  ]

  const totalPages = 1
  const displaySettings = {}

  return (
    <div className="container">
      <>
        <h1 className="my-3 text-center">Campaigns</h1>
        <p>
          Campaigns help to organize your search for talent. You can create a campaign and use it
          to keep track of the influencers you're considering for your next project.
        </p>
      </>
      <div className="row justify-content-center">
        <div className="col-3 text-center">
          <button
            data-purpose="create-new-campaign"
            className="btn btn-primary btn-large my-3"
            onClick={() => {
              dispatch(resetCampaign())
              setShowCreateModal(true)
            }}
          >
            Start New Campaign
          </button>
        </div>
      </div>

      {showPageMenu && (
        <PageMenu currentPage={page} totalPages={totalPages} baseUrl={pageBaseUrl} />
      )}

      <div className="row text-center">
        <h3>Your Current Campaigns</h3>
      </div>
      <div className={`row justify-content-center`}>
        <div className="col-12 col-md-6 text-center">
          {_.isEmpty(campaigns) ? (
            <div className="d-flex justify-content-center">
              <p>No campaigns! Create one.</p>
            </div>
          ) : (
            <>
              <table className="table table-striped">
                <thead>
                  <tr>
                    {tableColumns.map((column, index) => {
                      return <Fragment key={`column-${index}`}>{column.th}</Fragment>
                    })}
                  </tr>
                </thead>
                <tbody>
                  {_.orderBy(campaigns, (c) => Date(c.created_at), 'desc').map((campaign) => {
                    return (
                      <tr key={`campaign-${campaign.id}`}>
                        {tableColumns.map((column, index) => {
                          return (
                            <Fragment key={`column-${index}`}>
                              {column.td(campaign, displaySettings)}
                            </Fragment>
                          )
                        })}
                      </tr>
                    )

                    // {tableColumns.map((column, index) => {
                    //   return (
                    //     <Fragment key={`column-${index}`}>
                    //       {column.td(campaign, displaySettings)}
                    //     </Fragment>
                    //   )
                    // })}
                    // )
                  })}
                </tbody>
              </table>
            </>
          )}
        </div>
      </div>
      {/* <div className="d-flex justify-content-center">
        {_.isEmpty(campaigns) && <LoadingContainer />}
      </div> */}
      {showPageMenu && (
        <PageMenu currentPage={page} totalPages={totalPages} baseUrl={pageBaseUrl} />
      )}

      <ConfirmModal show={showDeleteModal} handleClose={handleCloseDeleteModal} />
      <CreateCampaignModal
        show={showCreateModal}
        handleClose={(confirmValue) => {
          setShowCreateModal(false)
          console.log('Creating', confirmValue)
          if (confirmValue) {
            dispatch(createCampaign(newCampaign))
            // navigate('/campaigns')
          }
        }}
      />
    </div>
  )
}
