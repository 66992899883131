import { useState } from 'react'
import { Badge } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/hooks'

import { updateParams } from 'redux/searchSlice'
import { setVisiblePopover } from 'redux/searchSlice'

const FormPopover = ({
  clickText,
  choiceText,
  popoverId,
  description,
  fields,
  handlePopoverConfirm,
  required_test,
}) => {
  // const [selectedValues, setSelectedValues] = useState({})
  // const [showPopover, setShowPopover] = useState(false)
  const dispatch = useDispatch()

  const showPopover = useSelector((state) => state.search.visiblePopover === popoverId)

  const searchParams = useSelector((state) => state.search.params)

  const handleSelectChange = (fieldIndex, value) => {
    // setSelectedValues((prevState) => ({
    //   ...prevState,
    //   [fieldIndex]: value,
    // }))

    dispatch(updateParams({ [fieldIndex]: value }))
  }

  const handleConfirmClick = () => {
    const valuesToTest = fields.reduce((acc, { param }) => {
      acc[param] = searchParams[param]
      return acc
    }, {})

    handlePopoverConfirm(valuesToTest)
    dispatch(setVisiblePopover(null))
    // setShowPopover(false)
  }

  const popover = (
    <Popover className="shadow" id={popoverId}>
      <Popover.Header as="h3">{description}</Popover.Header>
      <Popover.Body>
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{
            minWidth: '140px',
          }}
        >
          {fields.map(({ param, label, type, handleChange, groups }, index) => {
            // console.log(param, searchParams[param])
            return (
              <div key={`form-popover-field-${index}`} className="form-group mb-3 w-100">
                <label className="mb-1">{label}</label>
                <select
                  className="form-select form-select-sm w-100"
                  onChange={(e) => handleSelectChange(param, e.target.value)}
                  value={searchParams[param] || ''}
                >
                  {groups.map(({ label, value }, groupIndex) => {
                    return (
                      <option key={`group-${groupIndex}`} value={value}>
                        {label}
                      </option>
                    )
                  })}
                </select>
              </div>
            )
          })}
          <button className="btn btn-sm btn-primary mt-3" onClick={handleConfirmClick}>
            Confirm
          </button>
        </div>
      </Popover.Body>
    </Popover>
  )

  return (
    <>
      <div className="d-flex justify-content-between w-100">
        <OverlayTrigger
          trigger="click"
          placement="right"
          onToggle={() => dispatch(setVisiblePopover(showPopover ? null : popoverId))}
          show={showPopover}
          overlay={popover}
        >
          <div className="w-100">
            <div className="d-flex flex-column">
              <div className="fw-bold mb-2 text-uppercase">{clickText}</div>
            </div>
            <div className="w-100 bg-white form-select selectable">{choiceText}</div>
          </div>
        </OverlayTrigger>
        {/* {required_test(searchParams) && (
          <Badge
            bg="light"
            text="dark"
            className="selectable ms-2"
            onClick={() => handlePopoverConfirm({})}
          >
            <div className="d-flex align-items-center pt-2">x</div>
          </Badge>
        )} */}
      </div>
    </>
  )
}

export default FormPopover
