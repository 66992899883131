import { updateParams } from 'redux/searchSlice'
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/hooks'
import { joinClassNames } from 'utils'
import _ from 'lodash'
import { RootState } from 'store'
import React from 'react'
import { Field } from 'types'

// interface Group {
//   label: string;
//   value: string;
// }

// interface Field {
//   param: string;
//   label: string;
//   type: string;
//   groups: Group[];
// }

interface HighlightChooserProps {
  fields: Field[];
  clickText: string;
  processChoice?: (value: string, params: any) => Record<string, any>;
  valueIsChosen?: (value: string, params: any) => boolean;
  afterChange?: () => void;
}

const HighlightChooser: React.FC<HighlightChooserProps> = ({
  fields,
  clickText,
  processChoice,
  valueIsChosen,
  afterChange = () => {},
}) => {
  const dispatch = useDispatch()

  const params = useSelector((state: RootState) => state.search.params)

  return (
    <div className="col-12 mb-3">
      <div className="highlight-chooser">
        <div className="highlight-chooser__label fw-bold text-uppercase mb-2">{clickText}</div>
        <div className="d-flex flex-column justify-content-between">
          {fields[0]?.groups?.map(({ label, value }, index) => {
            const param = fields[0].param
            const isChosen = valueIsChosen ? valueIsChosen(value, params) : params[param] === value

            if (label === '--') return null
            return (
              <div
                key={`highlight-chooser-${index}`}
                onClick={() => {
                  if (processChoice) {
                    console.log(value, params)
                    const update = processChoice(value, params)
                    dispatch(updateParams(update))
                    afterChange()
                    return
                  }
                  if (params[param] === value) {
                    dispatch(updateParams({ [param]: null }))
                  } else {
                    dispatch(updateParams({ [param]: value }))
                  }
                  afterChange()
                }}
                className={joinClassNames([
                  'selectable',
                  'option',
                  'mb-1',
                  isChosen ? 'highlighted' : null,
                ])}
              >
                {label}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default HighlightChooser
