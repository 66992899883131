import _ from 'lodash'
import { Link } from 'react-router-dom'
import RenderResultsTable from 'RenderResultsTable'
import { useEffect, useState } from 'react'
import { fetchFavoriteInfluencers, toggleFavoriteInfluencer } from 'redux/userSlice'
import { safelyMillify } from 'utils'
import SocialIcon from 'SocialIcon'
import { fetchCampaign, toggleInfluencer } from 'redux/campaignsSlice'
import { showToast } from 'utils'
import CampaignChooserModal from 'CampaignChooserModal'
import { RootState } from 'store'
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/hooks'

const Favorites = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchFavoriteInfluencers())
  }, [])

  const [showCampaignModal, setShowCampaignModal] = useState(false)

  const favorites = useSelector((state: RootState) => state.user.favoriteInfluencers)

  const selectedCampaignId = useSelector((state: RootState) => state.campaigns.selectedCampaign)
  const campaigns = useSelector((state: RootState) => state.campaigns.entities)

  const getViews = (social: any) => {
    if (social.platform === 'instagram') {
      return social.avg_reels_plays
    }
    return social.avg_views
  }

  const getActiveCampaignInfluencers = () => {
    if (!selectedCampaignId) {
      return []
    }
    if (selectedCampaignId === 'favorites') {
      return []
    }

    const campaign = campaigns[selectedCampaignId]

    if (!campaign) {
      return []
    }

    return campaign.influencers || []
  }

  const campaignInfluencers = getActiveCampaignInfluencers()

  let campaign = selectedCampaignId ? campaigns[selectedCampaignId] : null

  const campaignIsSelected = selectedCampaignId && selectedCampaignId !== 'favorites'

  const socials = useSelector((state: RootState) => state.socials.entities)
  const influencers = useSelector((state: RootState) => state.influencers.entities)

  let columns = [
    {
      name: 'Talent',
      size: '5',
      sortValue: ({ social, influencer }: any) => {
        return influencer.fullname || social.platform_account_nickname
      },
      data: ({ social, influencer }: any) => {
        const genderAge = [
          influencer.gender ? influencer.gender[0] : null,
          influencer.age_range ? influencer.age_range : null,
        ]
          .filter(Boolean)
          .join(' / ')

        return (
          <div className="row align-items-center">
            {campaignIsSelected ? (
              <div className="col-2">
                <button
                  className="btn btn-light"
                  onClick={() => {
                    dispatch(
                      toggleInfluencer({
                        campaign_id: selectedCampaignId,
                        influencer_id: influencer.id,
                      }),
                    ).then(() => {
                      dispatch(fetchCampaign(selectedCampaignId))
                    })
                    showToast({ customText: 'Campaign Updated' })
                  }}
                >
                  {!campaignInfluencers.includes(influencer.id) ? 'Add' : 'x'}
                </button>
              </div>
            ) : (
              <div className="col-2">
                <button
                  className="btn btn-light"
                  onClick={() => {
                    dispatch(
                      toggleFavoriteInfluencer({
                        influencer_id: influencer.id,
                      }),
                    )
                    showToast({ customText: 'Favorites Updated' })
                  }}
                >
                  Remove
                </button>
              </div>
            )}
            <div className="col-3">
              <Link className="text-decoration-none" to={`/search/influencers/${influencer.id}`}>
                <img
                  style={{
                    width: '100px',
                    height: '100px',
                  }}
                  src={social.picture_main_url}
                  alt={influencer.fullname}
                  className="rounded-circle"
                />
              </Link>
            </div>
            <div className="col-7">
              <Link className="text-decoration-none" to={`/search/influencers/${influencer.id}`}>
                <div className="d-flex flex-column justify-content-between talent-name text-black">
                  <div className="fw-bold fullname">{influencer.fullname}</div>
                  {!_.isEmpty(genderAge) && (
                    <div
                      style={{
                        fontSize: '.85em',
                      }}
                    >
                      {genderAge}
                    </div>
                  )}
                  <div className="text-blue">
                    View Profile <span className="pb-1 text-blue fs-3">→</span>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        )
      },
    },
    {
      name: 'Socials',
      size: '7',
      data: ({ influencer }: any) => {
        const non_primary_accounts = influencer?.non_primary_accounts || []
        const social_accounts = [influencer.primary_account_id, ...non_primary_accounts].slice(
          0,
          4,
        )
        return (
          <div className="row align-items-center">
            {social_accounts.map((socialId: string, idx: number) => {
              const social = socials[socialId]
              if (!social) return null
              return (
                <div key={`social-${idx}`} className="col-3">
                  <div className="d-flex flex-column align-items-center">
                    <div className="text-black">
                      <SocialIcon platform={social.platform} />
                      <span className="ps-2">{social.platform_account_nickname}</span>
                    </div>
                    <div className="text-black">{safelyMillify(social?.followers)}</div>
                    {/* <div className="text-black-50">Followers</div> */}
                  </div>
                </div>
              )
            })}
          </div>
        )
      },
    },
  ].filter(Boolean)

  const results = favorites.map((id: string) => {
    const influencer = influencers[id]

    if (!influencer) {
      return null
    }

    return {
      influencer_id: influencer.id,
      social_account_id: influencer.primary_account_id,
    }
  })

  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-end mt-3">
          <div className="col-3">
            <button
              className="btn btn-light"
              onClick={() => {
                setShowCampaignModal(true)
              }}
            >
              {campaignIsSelected ? `Adding to: ${campaign?.title}` : 'Choose Campaign'}
            </button>
          </div>
        </div>
      </div>
      <RenderResultsTable columns={columns} results={results} blankMessage={'No Favorites'} />
      <CampaignChooserModal
        show={showCampaignModal}
        handleClose={() => setShowCampaignModal(false)}
      />
    </>
  )
}

export default Favorites
