import axios from 'axios'

export const sendEvent = async (event_type, data) => {
  console.log('sending event', event_type, data)
  return
  // const response = await axios.post('/api/analytics', {
  //   event_type,
  //   data,
  // })
  // return response.data
}
