import { useState } from 'react'

import normalMillify from 'millify'

import _ from 'lodash'
import { Link } from 'react-router-dom'
import { joinClassNames } from 'utils'
import SocialIcon from 'SocialIcon'
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/hooks'

import FavoriteStar from 'FavoriteStar'
import config from './config'
import { toggleFavoriteInfluencer } from 'redux/userSlice'
import { toggleCampaignInfluencer } from 'redux/campaignsSlice'
import RenderResultsTable from 'RenderResultsTable'
import axios from 'axios'
import { HashLoader } from 'react-spinners'
import CampaignChooserModal from 'CampaignChooserModal'
import { fetchCampaign } from 'redux/campaignsSlice'
import { Slide, toast } from 'react-toastify'
import { showToast } from 'utils'

const millify = (num) => {
  if (!num) {
    return ''
  }
  return normalMillify(num)
}

function roundToDecimalPlace(num, places = 1) {
  if (!num) return null
  const factor = Math.pow(10, places)
  return Math.round(num * factor) / factor
}

const SearchResults = ({ results, showCampaignModal, setShowCampaignModal }) => {
  const dispatch = useDispatch()

  const searchParams = useSelector((state) => state.search.params)
  const loadingState = useSelector((state) => state.search.loading)

  // const [showCampaignModal, setShowCampaignModal] = useState(false)

  const selectedCampaignId = useSelector((state) => state.campaigns.selectedCampaign)
  const campaigns = useSelector((state) => state.campaigns.entities)

  const getViews = (social) => {
    if (social.platform === 'instagram') {
      return social.avg_reels_plays
    }
    return social.avg_views
  }

  const favorites = useSelector((state) => state.user.favoriteInfluencers)

  const getActiveCampaignInfluencers = () => {
    if (!selectedCampaignId) {
      return favorites
    }
    if (selectedCampaignId === 'favorites') {
      return favorites
    }

    return campaigns[selectedCampaignId].influencers
  }

  const activeInfluencerList = getActiveCampaignInfluencers()

  const columns = [
    {
      name: 'Talent',
      size: '4',
      sortValue: ({ social, influencer }) => {
        return influencer.fullname || social.platform_account_nickname
      },
      data: ({ social, influencer }) => {
        const genderAge = [
          influencer?.gender ? influencer.gender[0] : null,
          influencer?.age_range ? influencer.age_range : null,
        ]
          .filter(Boolean)
          .join(' / ')

        return (
          <div className="row align-items-center">
            <div
              onClick={async () => {
                if (!selectedCampaignId) {
                  setShowCampaignModal(true)
                  return
                }

                if (selectedCampaignId === 'favorites') {
                  dispatch(toggleFavoriteInfluencer({ influencer_id: influencer.id }))
                } else {
                  dispatch(
                    toggleCampaignInfluencer({
                      id: selectedCampaignId,
                      influencer_id: influencer.id,
                    }),
                  )
                }
                // showToast({
                //   customText: 'Talent Added',
                // })
              }}
              className="col-2 text-center"
            >
              <FavoriteStar
                className="selectable"
                size={26}
                color={config.colors['primary-blue']}
                fillStar={activeInfluencerList.includes(influencer.id)}
              />
            </div>
            <div className="col-3">
              <Link className="text-decoration-none" to={`/search/influencers/${influencer.id}`}>
                <img
                  style={{
                    width: '100%',
                    // height: '80px',
                  }}
                  src={social.picture_main_url}
                  alt={social.platform_account_nickname}
                  className="rounded-circle"
                />
              </Link>
            </div>
            <div className="col-7">
              <Link className="text-decoration-none" to={`/search/influencers/${influencer.id}`}>
                <div className="d-flex flex-column justify-content-between talent-name text-black">
                  <div className="fw-bold fullname">{influencer.fullname}</div>
                  <div className="fst-italic handle">
                    <SocialIcon platform={social.platform} size={14} />
                    <span className="ms-1 text-gray">{social.platform_account_nickname}</span>
                  </div>
                  {!_.isEmpty(genderAge) && (
                    <div
                      style={{
                        fontSize: '.85em',
                      }}
                    >
                      {genderAge}
                    </div>
                  )}
                  <div className="text-blue">
                    View Profile <span className="pb-1 text-blue fs-3">→</span>
                  </div>
                </div>
              </Link>
              {/* <Link to={`/influencers/${influencer.id}`}>
                {_.uniq([influencer.fullname, social.platform_account_nickname])
                  .filter(Boolean)
                  .join(' / ')}
              </Link> */}
            </div>
          </div>
        )
      },
    },
    false && {
      id: 'name',
      name: 'Name/Handle',
      size: '2',
      sortValue: ({ social, influencer }) => {
        return influencer.fullname || social.platform_account_nickname
      },
      data: ({ social, influencer }) => {
        return (
          <>
            <Link to={`/influencers/${influencer.id}`}>
              {_.uniq([influencer.fullname, social.platform_account_nickname])
                .filter(Boolean)
                .join(' / ')}
            </Link>
          </>
        )
      },
    },
    {
      id: 'us_audience_share',
      name: 'US Audience',
      size: '1',
      sortValue: ({ social, influencer }) => {
        return Number(social.us_audience_share)
      },
      data: ({ social, influencer }) => {
        if (!social.us_audience_share || Number(social.us_audience_share) === 0) return null
        return `${Math.round(social.us_audience_share)}%`
      },
    },
    {
      name: 'Female/Male',
      sortValue: ({ social, influencer }) => {
        return Number(social.audience_female_percent)
      },
      size: '1',
      data: ({ social, influencer }) => {
        if (!social.audience_female_percent && !social.audience_male_percent) return null
        return `${Math.round(social.audience_female_percent)}% / ${Math.round(
          social.audience_male_percent,
        )}%`
      },
    },
    {
      id: 'followers',
      name: 'Followers',
      size: '1',
      sortValue: ({ social, influencer }) => {
        return social.followers
      },
      data: ({ social, influencer }) => {
        if (!social.followers) return null
        return millify(social.followers)
      },
    },
    searchParams.min_age_share &&
      searchParams.min_age_share && {
        id: 'audience_age_share',
        name: `Audience ${searchParams.audience_age_min} - ${searchParams.audience_age_max}`,
        size: '1',
        // sortValue: ({ social, influencer }) => {
        //   const audienceParam = searchParams.audience_age_range.replace('-', '_')
        //   return social[`audience_age_share_${audienceParam}`]
        // },
        data: ({ social, influencer }) => {
          // return "HELLO"
          let audienceAgeDescriptor = [
            searchParams.audience_age_min,
            searchParams.audience_age_max,
          ].join('_')

          const audienceParam = searchParams.audience_age_min
          const sharePct = social[`audience_age_share_${audienceAgeDescriptor}`]
          if (!sharePct) return null
          return `${Math.round(sharePct)}%`
        },
      },
    {
      id: 'engagement_rate',
      name: 'Engagement Rate',
      size: '1',
      sortValue: ({ social, influencer }) => {
        return Number(social.engagement_rate)
      },
      data: ({ social, influencer }) => {
        if (!social.engagement_rate) return null
        return `${roundToDecimalPlace(social.engagement_rate)}%`
      },
    },
    {
      id: 'avg_views',
      name: 'Average Views',
      size: '1',
      sortValue: ({ social, influencer }) => {
        return getViews(social) || 0
      },
      data: ({ social, influencer }) => {
        return social.platform === 'instagram'
          ? millify(social.avg_reels_plays)
          : millify(social.avg_views)
      },
    },
    // searchParams.tags?.length > 0 && {
    //   name: 'Tags',
    //   size: '1',
    //   data: ({ social, influencer }) => {
    //     const matchingTags = influencer.tags?.filter((tag) => searchParams.tags.includes(tag))
    //     if (!matchingTags?.length > 0) return null
    //     return matchingTags.map((t, i) => {
    //       return (
    //         <div
    //           style={{
    //             fontSize: '.8em',
    //           }}
    //           key={`tag-${i}`}
    //           className="tag badge rounded-pill bg-info text-white mb-1 py-2"
    //         >
    //           {t.length > 16 ? `${t.slice(0, 12)}...` : t}
    //         </div>
    //       )
    //     })
    //   },
    // },
  ].filter(Boolean)

  if (loadingState === 'pending') {
    return (
      <div className="d-flex text-center justify-content-center my-4">
        <HashLoader color={config.colors['primary-blue']} />
      </div>
    )
  }

  return (
    <>
      <RenderResultsTable columns={columns} results={results} />
      <CampaignChooserModal
        show={showCampaignModal}
        handleClose={() => setShowCampaignModal(false)}
      />
    </>
  )
}

export default SearchResults
