import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  createSelector,
  EntityState,
} from '@reduxjs/toolkit'

import _ from 'lodash'
import axios from 'axios'
import { performSearch, performUpdate } from './searchSlice'
import { fetchFavoriteInfluencers } from './userSlice'
import { fetchCampaign } from './campaignsSlice'
import { RootState } from 'store'
import { Influencer, Social, Sheet } from 'types'

interface FetchSingleInfluencerPayload {
  influencer_id: string
}

interface FetchSingleInfluencerResponse {
  influencers: Influencer[]
  socials: Social[]
  sheets: Sheet[]
  error: boolean
}

interface FetchPublicInfluencerProfilePayload {
  influencer_id: string
  team_id?: string
}

interface FetchPublicInfluencerProfileResponse {
  influencers?: Influencer[]
  socials: Social[]
  sheet?: {
    settings?: any // Update with the actual type of settings
    team_name?: string
    team_image_url?: string
  }
}

export const fetchSingleInfluencer = createAsyncThunk<
  FetchSingleInfluencerResponse,
  FetchSingleInfluencerPayload
>('influencers/fetchOne', async ({ influencer_id }) => {
  try {
    const response = await axios.get(`/api/influencers/profiles/${influencer_id}`)
    return response.data
  } catch (e) {
    return {
      influencers: [],
      influencer_id,
      error: true,
      data: _.get(e, 'response.data'),
    }
  }
})

export const fetchPublicInfluencerProfile = createAsyncThunk<
  FetchPublicInfluencerProfileResponse,
  FetchPublicInfluencerProfilePayload
>('influencers/fetchPublicInfluencerProfile', async ({ influencer_id }) => {
  let url = `/api/public/profile/${influencer_id}`
  
  const response = await axios.get(url, {
    params: {
      search_tool: true,
    },
  })
  return response.data
})

const influencersAdapter = createEntityAdapter<Influencer>()

interface InfluencersState extends EntityState<Influencer> {
  loading: 'idle' | 'pending'
  teamSettings: {
    [key: string]: any // Update with the actual type of team settings
    name?: string
    teamLogo?: string
  }
}

const initialState: InfluencersState = influencersAdapter.getInitialState({
  loading: 'idle',
  teamSettings: {},
})

const influencerSlice = createSlice({
  name: 'influencers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(performSearch.fulfilled, (state, { payload }) => {
        influencersAdapter.upsertMany(state, payload.influencers)
        state.loading = 'idle'
      })
      .addCase(performUpdate.fulfilled, (state, { payload }) => {
        influencersAdapter.upsertMany(state, payload.influencers)
        state.loading = 'idle'
      })
      .addCase(fetchSingleInfluencer.fulfilled, (state, { payload }) => {
        if (!payload.error) {
          const influencer = {
            ...payload.influencers[0],
          }
          influencersAdapter.upsertOne(state, influencer)
        } else {
          console.log('error', payload.error)
        }
        state.loading = 'idle'
      })
      .addCase(fetchPublicInfluencerProfile.pending, (state) => {
        state.loading = 'pending'
      })
      .addCase(fetchPublicInfluencerProfile.fulfilled, (state, { payload }) => {
        if (!payload.influencers) return
        influencersAdapter.upsertMany(state, payload.influencers)
        state.loading = 'idle'
        if (payload.sheet?.settings) {
          state.teamSettings = payload.sheet.settings
        }
        state.teamSettings = {
          ...state.teamSettings,
          name: payload.sheet?.team_name,
          teamLogo: payload.sheet?.team_image_url,
        }
      })
      .addCase(fetchFavoriteInfluencers.fulfilled, (state, { payload }) => {
        if (!payload.influencers) return
        influencersAdapter.upsertMany(state, payload.influencers)
        state.loading = 'idle'
      })
      .addCase(fetchCampaign.fulfilled, (state, { payload }) => {
        if (!payload.influencers) return
        influencersAdapter.upsertMany(state, payload.influencers)
        state.loading = 'idle'
      })
  },
})

export const {} = influencerSlice.actions

// selector
const selectInfluencers = (state: RootState) => state.influencers.entities

// reselect function
export const selectInfluencersMemoized = createSelector(
  [selectInfluencers],
  (influencers) => influencers,
)

export default influencerSlice.reducer
