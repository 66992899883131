import { useEffect } from 'react'
import { joinClassNames } from 'utils'
// import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/hooks'
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/hooks'

import {
  disableNotification,
  fetchConversations,
  archiveConversation,
  updateReply,
  sendReply,
  fetchOneConversation,
} from './redux/conversationSlice'
import _ from 'lodash'
import { FaArrowLeft } from 'react-icons/fa'
import BulletPoint from 'components/BulletPoint'
import { Form, Button } from 'react-bootstrap'
import { BiArchiveIn } from 'react-icons/bi'
import { useSearchParams } from 'react-router-dom'
import RenderConversation from 'components/RenderConversation'

const displayStringOrTruncate = (str, maxLength) => {
  if (str.length > maxLength) {
    return `${str.slice(0, maxLength)}...`
  } else {
    return str
  }
}

function Conversations() {
  const dispatch = useDispatch()
  const conversations = useSelector((state) => state.conversations.conversations.entities)
  const messages = useSelector((state) => state.conversations.messages.entities)

  const newReply = useSelector((state) => state.conversations.newReply)

  let [searchParams, setSearchParams] = useSearchParams()

  const currentConversationId = searchParams.get('conversation_id')

  useEffect(() => {
    dispatch(fetchConversations())
    dispatch(disableNotification())
  }, [])

  function handleConversationClick(conversation) {
    // if (conversation.has_unread) {
    //   dispatch(markAsRead({ conversation_id: conversation.id })).then((data) => {
    //     dispatch(fetchConversations())
    //   })
    // }

    setSearchParams({
      ...searchParams,
      conversation_id: conversation.id,
    })
  }

  const selectedConversation = currentConversationId ? conversations[currentConversationId] : null

  const conversationArray = _.orderBy(conversations, 'most_recent_message_timestamp', 'desc')

  const handleSubmit = (e) => {
    e.preventDefault()
    if (newReply.body.trim() === '') {
      return
    }
    dispatch(sendReply({ reply: newReply })).then((data) => {
      dispatch(fetchOneConversation({ conversationId: selectedConversation.id }))
      dispatch(updateReply({ conversation_id: null, body: '' }))
    })
  }

  return (
    <>
      {selectedConversation ? (
        <>
          <div className="container mt-4">
            <div className="selectable">
              <div
                className="fs-5 icon-wrapper mb-3 p-1"
                // style={{ fontSize: '1.8em', position: 'absolute', top: '10px', left: '50px' }}
                data-purpose="back-arrow"
                onClick={() => {
                  searchParams.delete('conversation_id')
                  setSearchParams({
                    ...searchParams,
                  })
                  // setCurrentConversationId(null)
                }}
              >
                <FaArrowLeft className="arrow-icon" />
              </div>
            </div>
            <RenderConversation conversation={selectedConversation} />
            {/* {!newReply.conversation_id && (
              <>
                <button
                  onClick={() => {
                    dispatch(archiveConversation({ conversationId: selectedConversation.id }))
                    searchParams.delete('conversation_id')
                    setSearchParams({
                      ...searchParams,
                    })
                  }}
                  className="btn btn-danger mt-3"
                >
                  Archive
                  <BiArchiveIn className="ms-2" />
                </button>
              </>
            )} */}
          </div>
          {newReply.conversation_id && (
            <div className="container mt-4">
              <h3>Reply</h3>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Control
                    as="textarea"
                    rows={8}
                    value={newReply.body}
                    onChange={(e) => dispatch(updateReply({ body: e.target.value }))}
                    placeholder="Write your message here"
                  />
                </Form.Group>
                <Button className="me-3" variant="primary" type="submit">
                  Send
                </Button>
                <Button
                  variant="danger"
                  onClick={() => {
                    dispatch(updateReply({ conversation_id: null, body: '' }))
                  }}
                >
                  Cancel
                </Button>
              </Form>
            </div>
          )}
        </>
      ) : (
        <div className="container mt-3">
          <h2 className="mb-3">Messages</h2>

          {conversationArray.length === 0 && (
            <div className="alert alert-info">
              <p>You have no more messages.</p>
            </div>
          )}
          <div className="message-list">
            <div className="list-group">
              {_.orderBy(conversations, 'most_recent_message_timestamp', 'desc').map(
                (conversation) => {
                  const message_ids = conversation.message_ids

                  if (message_ids.length === 0) {
                    return null
                  }

                  // get the last message in the conversation
                  const latestMessage = messages[message_ids[message_ids.length - 1]]

                  return (
                    <div
                      key={conversation.id}
                      onClick={() => handleConversationClick(conversation)}
                    >
                      <div
                        className={joinClassNames([
                          'list-group-item list-group-item-action selectable',
                          currentConversationId === conversation.id ? 'active' : null,
                        ])}
                        aria-current="true"
                      >
                        <div className="d-flex w-100 justify-content-between">
                          <div className="row w-100">
                            <div className="col-3">
                              <div className="fw-bold w-100">{latestMessage.sender_name}</div>
                            </div>
                            <div className="col-9">
                              <div className="w-100">
                                <h5
                                  className={joinClassNames([
                                    'mb-1',
                                    conversation.has_unread_message ? 'fw-bold' : null,
                                  ])}
                                >
                                  {conversation.has_unread_message && <BulletPoint color="blue" />}{' '}
                                  {conversation.title}
                                </h5>
                                <p className="mb-1 ms-3">
                                  {displayStringOrTruncate(latestMessage.body, 45)}
                                </p>
                              </div>
                            </div>
                          </div>

                          <small>{new Date(conversation.created_at).toLocaleDateString()}</small>
                        </div>
                      </div>
                    </div>
                  )
                },
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Conversations
