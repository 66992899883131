import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit'
import axios from 'axios'
import { Team } from '../types'
import _ from 'lodash'

interface TeamResponse {
  teams: Team[]
  featured_agency_id: string
}

export const fetchTeams = createAsyncThunk<TeamResponse>('teams/fetchAll', async () => {
  const response = await axios.get<TeamResponse>('/api/directory/partners')
  return response.data
})

const teamsAdapter = createEntityAdapter<Team>()

export const teamsSlice = createSlice({
  name: 'teams',
  initialState: teamsAdapter.getInitialState<{
    selectedTeam: Team | null
    featuredTeam: string | null
  }>({
    selectedTeam: null,
    featuredTeam: null,
  }),
  reducers: {
    setSelectedTeam: (state, action: PayloadAction<Team>) => {
      state.selectedTeam = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTeams.fulfilled, (state, { payload }) => {
      teamsAdapter.upsertMany(state, payload.teams)

      state.featuredTeam = payload.featured_agency_id
    })
  },
})

// Action creators are generated for each case reducer function
export const { setSelectedTeam } = teamsSlice.actions

export default teamsSlice.reducer
