import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  createSelector,
  PayloadAction,
} from '@reduxjs/toolkit'

import _ from 'lodash'
import axios from 'axios'

import { Campaign } from 'types'
import { RootState } from 'store'

interface NewCampaign {
  submitted: boolean
  influencers: string[]
  title: string
}

interface CampaignsState {
  total_campaigns: number | null
  error: any
  ordered_sheets: string[]
  newCampaign: NewCampaign
  selectedCampaign: string
}

const selectCampaignsEntities = (state: RootState) => state.campaigns.entities
const selectSelectedCampaign = (state: RootState) => state.campaigns.selectedCampaign
const selectFavoriteInfluencers = (state: RootState) => state.user.favoriteInfluencers

export const fetchCampaign = createAsyncThunk('campaign/fetchOne', async (hash_id: string) => {
  const response = await axios.get(`/api/directory/campaigns/${hash_id}`)
  const data = response.data
  if (data.accessDenied) {
    return {
      accessDenied: true,
      campaign_id: hash_id,
    }
  }
  return data
})

export const fetchCampaigns = createAsyncThunk(
  'campaign/fetchMany',
  async ({ page = 1, limit = 50 }: { page?: number; limit?: number } = {}) => {
    const response = await axios.get(`/api/directory/campaigns`, {
      params: {
        page,
        limit,
      },
    })
    return response.data
  },
)

export const createCampaign = createAsyncThunk(
  'campaign/create',
  async ({ title, influencers }: { title: string; influencers: { id: string }[] }) => {
    const response = await axios.post(`/api/directory/campaigns`, {
      title,
      influencers: influencers.map((inf) => inf.id),
    })
    return response.data
  },
)

export const updateCampaign = createAsyncThunk(
  'campaign/update',
  async ({
    id,
    title,
    influencers,
  }: {
    id: string
    title: string
    influencers: { id: string }[]
  }) => {
    const response = await axios.put(`/api/directory/campaigns/${id}`, {
      title,
      influencers: influencers.map((inf) => inf.id),
    })
    return response.data
  },
)

export const toggleCampaignInfluencer = createAsyncThunk(
  'campaign/setInfluencers',
  async ({ id, influencer_id }: { id: string; influencer_id: string }, thunkAPI: any) => {
    const state = thunkAPI.getState() as RootState

    const campaign = state.campaigns.entities[id]
    if (!campaign) {
      throw new Error(`Campaign with id ${id} not found`)
    }
    console.log('current', campaign.influencers)

    const newInfluencers = campaign.influencers.includes(influencer_id)
      ? campaign.influencers.filter((id) => id !== influencer_id)
      : [...campaign.influencers, influencer_id]

    const response = await axios.put(`/api/directory/campaigns/${campaign.id}`, {
      influencers: newInfluencers,
    })

    return { campaign_id: id, influencers: newInfluencers }
  },
)

export const deleteCampaign = createAsyncThunk(
  'campaign/delete',
  async ({ id }: { id: string }) => {
    const response = await axios.delete(`/api/directory/campaigns/${id}`)
    return {
      id,
    }
  },
)

export const toggleInfluencer = createAsyncThunk(
  'campaign/toggleInfluencer',
  async (
    { campaign_id, influencer_id }: { campaign_id: string; influencer_id: string },
    thunkAPI: any,
  ) => {
    const state = thunkAPI.getState() as RootState
    const campaign = state.campaigns.entities[campaign_id]

    if (!campaign) {
      throw new Error(`Campaign with id ${campaign_id} not found`)
    }

    let influencers = campaign.influencers
    const index = influencers.indexOf(influencer_id)

    if (index !== -1) {
      influencers = influencers.filter((id) => id !== influencer_id)
    } else {
      influencers = [...influencers, influencer_id]
    }

    const response = await axios.put(`/api/directory/campaigns/${campaign_id}`, {
      influencers,
    })
    return response.data
  },
)

const campaignsAdapter = createEntityAdapter<Campaign>()

export const campaignsSlice = createSlice({
  name: 'campaigns',
  initialState: campaignsAdapter.getInitialState<CampaignsState>({
    total_campaigns: null,
    error: null,
    ordered_sheets: [],
    newCampaign: {
      submitted: false,
      influencers: [],
      title: '',
    },
    selectedCampaign: 'favorites',
  }),
  reducers: {
    selectCampaign: (state, { payload }: PayloadAction<string>) => {
      state.selectedCampaign = payload
    },
    editCampaign: (state, action: PayloadAction<{ id: string; changes: Partial<Campaign> }>) => {
      const { id, changes } = action.payload
      const campaign = state.entities[id]
      if (campaign) {
        Object.assign(campaign, changes, { synced: false })
      }
    },
    removeCampaign: (state, { payload }: PayloadAction<string>) => {
      console.log(`remove ${payload}`)
      campaignsAdapter.removeOne(state, payload)
    },
    upsertCampaign: (state, { payload }: PayloadAction<{ sheet: Campaign }>) => {
      campaignsAdapter.upsertOne(state, payload.sheet)
    },
    updateSheetPublicTitle: (
      state,
      { payload }: PayloadAction<{ id: string; changes: Partial<Campaign> }>,
    ) => {
      campaignsAdapter.updateOne(state, payload)
    },
    resetCampaign: (state) => {
      state.newCampaign = {
        submitted: false,
        influencers: [],
        title: '',
      }
    },
    updateNewCampaign: (state, { payload }: PayloadAction<Partial<NewCampaign>>) => {
      state.newCampaign = {
        ...state.newCampaign,
        ...payload,
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCampaign.fulfilled, (state, { payload }) => {
        if (payload.accessDenied) {
          localStorage.setItem('accessDeniedCampaignId', payload.campaign_id)
        }

        campaignsAdapter.upsertOne(state, payload.campaign)
      })
      .addCase(fetchCampaigns.fulfilled, (state, { payload }) => {
        campaignsAdapter.upsertMany(state, payload.campaigns)
        state.total_campaigns = payload.total_count
        state.ordered_sheets = payload.ordered_sheets
      })
      .addCase(createCampaign.fulfilled, (state, { payload }) => {
        campaignsAdapter.upsertOne(state, payload.campaign)
      })
      .addCase(updateCampaign.fulfilled, (state, { payload }) => {
        campaignsAdapter.upsertOne(state, payload.campaign)
        state.newCampaign = {
          submitted: false,
          influencers: [],
          title: '',
        }
      })
      .addCase(deleteCampaign.fulfilled, (state, { payload }) => {
        campaignsAdapter.removeOne(state, payload.id)
      })
      .addCase(toggleCampaignInfluencer.fulfilled, (state, { payload }) => {
        const campaign = state.entities[payload.campaign_id]
        if (campaign) {
          campaign.influencers = payload.influencers
        }
      })
  },
})

export const selectedCampaignSelector = createSelector(
  [selectSelectedCampaign, selectFavoriteInfluencers, selectCampaignsEntities],
  (selectedCampaignId, favoriteInfluencers, entities) => {
    if (!selectedCampaignId) {
      return null
    }
    if (selectedCampaignId === 'favorites') {
      return { title: 'Favorites', id: 'favorites', influencers: favoriteInfluencers }
    }
    return entities[selectedCampaignId]
  },
)

export const {
  removeCampaign,
  upsertCampaign,
  editCampaign,
  resetCampaign,
  updateNewCampaign,
  selectCampaign,
} = campaignsSlice.actions

export default campaignsSlice.reducer
