import { configureStore } from '@reduxjs/toolkit'
import influencerReducer from './redux/influencerSlice'
import searchReducer from './redux/searchSlice'
import socialsReducer from './redux/socialSlice'
import userReducer from './redux/userSlice'
import conversationsReducer from './redux/conversationSlice'
import teamsReducer from './redux/teamsSlice'
import campaignsReducer from './redux/campaignsSlice'

const store = configureStore({
  reducer: {
    teams: teamsReducer,
    user: userReducer,
    search: searchReducer,
    influencers: influencerReducer,
    socials: socialsReducer,
    conversations: conversationsReducer,
    campaigns: campaignsReducer,
  },
})

export default store

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch