import _ from 'lodash'
import { useState, useCallback } from 'react'
import CommonFormWrapper from 'components/CommonFormWrapper'

import React from 'react'
// import './styles/Login.scss'
import { setUser } from './redux/userSlice'
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/hooks'
import axios from 'axios'
import config from './config'

const SignupPage = () => {
  const [referredBy, setReferredBy] = useState('Todd Murray') // Default value

  const blankForm = {
    name: '',
    note: '',
    email: '',
    organization: '',
  }
  const [uploadedKey, setUploadedKey] = useState(null)
  const [data, setData] = useState(blankForm)
  const [formSent, setFormSent] = useState(false)
  const [error, setError] = useState(null)
  const [savedMessage, setSavedMessage] = useState(null)

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (data.email.trim() === '') {
      setError('Please include an email so we can contact you')
      return
    }
    try {
      const formData = {
        ...data,
        referredBy,
      }
      if (uploadedKey) {
        formData.uploadedKey = uploadedKey
      }

      axios.post('/api/signup-form', formData).then((r) => {
        setFormSent(true)
        setSavedMessage(r.data.message)
        setData(blankForm)
      })

      return
    } catch (e) {
      console.log(e)
      setError(
        'Something went wrong while trying to sign up. Do you already have an account? Try signing in with that email, or contact us.',
      )
    }
  }

  if (formSent) {
    return (
      <CommonFormWrapper>
        <h2 className="text-center">Contact Information Sent</h2>
        <p className="my-3 text-center">
          Thanks for signing up! Check your inbox (or spam folder) for an email from us with more
          information.
        </p>
      </CommonFormWrapper>
    )
  }

  return (
    <div className="container-fluid login bg-gray-800">
      <div
        style={{
          minWidth: '65%',
        }}
        className="row gx-0"
      >
        <div className="col-12 col-md-5 d-flex align-items-center bg-gray-800 text-white welcome-box">
          <div
            style={{
              paddingRight: '10%',
            }}
          >
            <div className="logo-container text-center mb-4">
              <img src={config.gayborImgURL} alt="Company Logo" className="company-logo" />
            </div>
            <p
              style={{
                fontSize: '1.2rem',
              }}
            ></p>
          </div>
        </div>
        <div className="col-12 col-md-7 d-flex align-items-center bg-white p-5">
          <div className="w-100">
            <h1 className="mb-3 text-center">Schedule a Demo</h1>
            <div className="row justify-content-center px-3">
              <div className="col-12">
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Your Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Your name"
                      value={data.name}
                      onChange={(e) => setData({ ...data, name: e.target.value })}
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="Your email"
                      value={data.email}
                      onChange={(e) => setData({ ...data, email: e.target.value })}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="organization" className="form-label">
                      Brand/Agency
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="organization"
                      placeholder="Your organization"
                      value={data.organization}
                      onChange={(e) => setData({ ...data, organization: e.target.value })}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="referredBy" className="form-label">
                      Referred By (please select)
                    </label>
                    <select
                      className="form-control"
                      id="referredBy"
                      value={referredBy}
                      onChange={(e) => setReferredBy(e.target.value)}
                    >
                      <option value="Todd Murray">Todd Murray</option>
                      <option value="Evie Hatmaker">Evie Hatmaker</option>
                    </select>
                  </div>
                  <div>
                    <textarea
                      rows={4}
                      className="form-control"
                      placeholder="Other Information (optional)"
                      value={data.note}
                      onChange={(e) => setData({ ...data, note: e.target.value })}
                    />
                  </div>
                  <div className="message-box mt-2">
                    {error && <div className="error">{error}</div>}
                  </div>

                  <div className="d-flex justify-content-center">
                    <input
                      className="btn btn-primary sign-in-btn my-3"
                      type="submit"
                      value="Send"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignupPage
