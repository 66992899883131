import { useState, useEffect, useRef, useMemo } from 'react'
import { selectSocialsMemoized, socialsSelector } from 'redux/socialSlice'
import { selectInfluencersMemoized } from 'redux/influencerSlice'

import _ from 'lodash'
import { joinClassNames } from 'utils'
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/hooks'

import { performUpdate, setSortBy, setSortOrder } from 'redux/searchSlice'
import { useParams } from 'react-router-dom'

const RenderResultsTable = ({ columns, results, blankMessage }) => {
  const dispatch = useDispatch()
  const urlParams = useParams()

  const { hasMoreResults, nextPage, loadingState } = useSelector((state) => state.search)

  const loadNextPage = () => {
    if (loadingState === 'pending' || loadingState === 'initial' || loadingState === 'appending') {
      return
    }
    dispatch(performUpdate({ append: true, team_id: urlParams.team_id, page: nextPage }))
  }

  const loadMoreRef = useRef(null)

  useEffect(() => {
    if (hasMoreResults) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            loadNextPage()
          }
        },
        { threshold: 1 },
      )

      if (loadMoreRef.current) {
        observer.observe(loadMoreRef.current)
      }

      return () => {
        if (loadMoreRef.current) {
          observer.unobserve(loadMoreRef.current)
        }
      }
    }
  }, [loadMoreRef, hasMoreResults]) // Observing for changes to these dependencies

  const socials = useSelector((state) => state.socials.entities)
  const influencers = useSelector((state) => state.influencers.entities)

  const sortBy = useSelector((state) => state.search.sortBy)
  const sortOrder = useSelector((state) => state.search.sortOrder)

  const orderByColumn = (result) => {
    const column = columns.find((column) => column.id === sortBy)

    if (!column || !column.sortValue) {
      return 0
    }

    return column.sortValue({
      social: {
        ...socials[result.social_account_id],
        ...result,
      },
      influencer: influencers[result.influencer_id],
    })
  }

  const uniqueResults = useMemo(() => {
    return _.uniqBy(results, (result) => {
      return `${result.social_account_id}-${result.social_account_id}}`
    })
  }, [results])

  return (
    <div className="container mt-3 custom-table shadow">
      <div className="row bg-primary text-white fw-bold table-head-row justify-content-between">
        {columns.map((column, index) => {
          const size = column.size || '2'

          return (
            <div
              key={`th-${index}`}
              className={joinClassNames([
                `col-${size} table-cell text-center d-flex align-items-center justify-content-center`,
                column.sortValue && 'selectable',
              ])}
              onClick={() => {
                if (!column.sortValue) return
                if (sortBy === column.id) {
                  dispatch(setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc'))
                } else {
                  dispatch(setSortBy(column.id))
                  dispatch(setSortOrder('desc'))
                }
              }}
            >
              {column.name}
              {sortBy === column.id && (
                <span style={{ marginLeft: '4px' }}>{sortOrder === 'asc' ? '▲' : '▼'}</span>
              )}
            </div>
          )
        })}
      </div>
      {_.isEmpty(results) ? (
        <div className="row table-body-row">
          <div className="col-12 text-center py-3">
            {blankMessage || 'No Matching Results. Try removing some filters'}
          </div>
        </div>
      ) : (
        <>
          {_.orderBy(uniqueResults, orderByColumn, sortOrder).map((result, index) => {
            const social = socials[result.social_account_id] || {}
            const influencer = influencers[result.influencer_id]

            if (!influencer || !social) {
              console.log('Missing influencer or social', result)
              return null
            }

            return (
              <div key={`row-${index}`} className="row table-body-row justify-content-between">
                {columns.map((column, index) => {
                  const size = column.size || '2'
                  return (
                    <div key={`row-${index}`} className={`col-${size} table-cell text-center`}>
                      {column.data({
                        social: {
                          ...social,
                          ...result,
                        },
                        influencer,
                      })}
                    </div>
                  )
                })}
              </div>
            )
          })}
          {hasMoreResults && (
            <div
              style={{
                marginTop: '80px',
              }}
              ref={loadMoreRef}
            >
              .
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default RenderResultsTable
