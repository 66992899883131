import { HashLoader } from 'react-spinners'
import config from '../config'

export default function LoadingContainer({ customText = 'Loading...' }) {
  return (
    <div className="container">
      <div className="d-flex flex-column justify-content-center">
        <div>
          <p className="text-center text-bold mt-3">{customText}</p>
        </div>
        <div className="d-flex text-center justify-content-center my-4">
          <HashLoader color={config.colors['primary-blue']} />
        </div>
      </div>
    </div>
  )
}
