import _ from 'lodash'
import axios from 'axios'
import { useState, useCallback } from 'react'
import CommonFormWrapper from 'components/CommonFormWrapper'
import { useDropzone } from 'react-dropzone'

function ContactForm() {
  const blankForm = {
    note: '',
  }
  const [uploadedKey, setUploadedKey] = useState(null)
  const [data, setData] = useState(blankForm)
  const [formSent, setFormSent] = useState(false)
  const [error, setError] = useState(null)
  const [savedMessage, setSavedMessage] = useState(null)

  const uploadPhoto = async (file) => {
    if (!file) return
    try {
      const { data } = await axios.get('/api/media-upload', {
        params: {
          filename: file.name,
          type: file.type,
        },
      })

      const upload = await axios.put(data.url, file, {
        headers: {
          'x-amz-acl': 'public-read',
          'Content-Type': file.type,
        },
      })

      setUploadedKey(data.key)
    } catch (e) {
      console.log(e)
      setError('Something went wrong with the upload.')
    }
  }

  const onDrop = useCallback((acceptedFiles) => {
    console.log(acceptedFiles)
    uploadPhoto(acceptedFiles[0])
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (data.note.trim() === '') {
      setError('You must include a message')
      return
    }
    try {
      const formData = {
        ...data,
      }
      if (uploadedKey) {
        formData.uploadedKey = uploadedKey
      }

      axios.post('/api/user/contact-tpp', formData).then((r) => {
        setFormSent(true)
        setSavedMessage(r.data.message)
        setData(blankForm)
      })

      return
    } catch (e) {
      console.log(e)
      setError(
        'Something went wrong while trying to send your message, sorry. Please try again later',
      )
    }
  }

  return (
    <CommonFormWrapper>
      <>
        {formSent ? (
          <>
            <h2 className="text-center">Message Sent</h2>
            <p className="my-3 text-center">
              We have received your message and will be in touch shortly. Thank you!
            </p>
          </>
        ) : (
          <>
            <h1 className="mb-3 text-center">Contact Us</h1>
            <div className="row justify-content-center px-3">
              <div className="col-12">
                <form className="influencer-request-form" onSubmit={handleSubmit}>
                  <p>Problem with the site or otherwise need help? Let us know.</p>
                  <div>
                    <textarea
                      rows={8}
                      className="form-control"
                      placeholder="Your message"
                      value={data.note}
                      onChange={(e) => setData({ ...data, note: e.target.value })}
                    />
                  </div>

                  {false && (
                    <div className="d-flex flex-column justify-content-center mt-3">
                      {uploadedKey ? (
                        <div className="d-flex justify-content-center">
                          <div className="d-flex flex-column">
                            <div className="text-center">
                              <img
                                style={{
                                  objectFit: 'cover',
                                  objectPosition: 'center',
                                  width: '80%',
                                  // height: '240px',
                                  // borderRadius: '50%',
                                }}
                                src={`https://tpp-uploads.us-east-1.linodeobjects.com/${uploadedKey}`}
                                alt="uploaded"
                              />
                            </div>

                            <div className="text-center mt-3">
                              <button
                                className="btn btn-danger"
                                onClick={(e) => {
                                  e.preventDefault()
                                  setUploadedKey(null)
                                }}
                              >
                                Remove File
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="mb-3">
                          <div {...getRootProps({ className: 'dropzone selectable' })}>
                            <input {...getInputProps()} />
                            <p>
                              If needed, you can drag and drop an image file here, or click to
                              select a file for upload
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="message-box mt-2">
                    {error && <div className="error">{error}</div>}
                  </div>

                  <div className="d-flex justify-content-center">
                    <input className="btn btn-primary my-3" type="submit" value="Send" />
                  </div>
                </form>
              </div>
            </div>
          </>
        )}
      </>
    </CommonFormWrapper>
  )
}

export default ContactForm
