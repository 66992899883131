import React, { useEffect } from 'react'
import config from 'config'
import { Helmet } from 'react-helmet'
import 'styles/_Terms.scss'

const Terms = () => {
  return (
    <div className="terms-page">
      <Helmet>
        <meta charset="utf-8" />
        <title>Terms and Conditions</title>
        <meta
          content="Gaybors Marketplace is an online service that helps talent managers get to the deal closing stage faster. It saves your time by creating pitches of your talent roster based on your clients needs."
          name="description"
        />
        <meta content="Terms and Conditions" property="og:title" />
        <meta
          content="Gaybors Marketplace is an online service that helps talent managers get to the deal closing stage faster. It saves your time by creating pitches of your talent roster based on your clients needs."
          property="og:description"
        />
        <meta
          content="https://uploads-ssl.webflow.com/63585b544e040a3c30bb76b9/6431199f92ccec13f3118919_interface%201.png"
          property="og:image"
        />
        <meta content="Terms and Conditions" property="twitter:title" />
        <meta
          content="Gaybors Marketplace is an online service that helps talent managers get to the deal closing stage faster. It saves your time by creating pitches of your talent roster based on your clients needs."
          property="twitter:description"
        />
        <meta
          content="https://uploads-ssl.webflow.com/63585b544e040a3c30bb76b9/6431199f92ccec13f3118919_interface%201.png"
          property="twitter:image"
        />
        <meta property="og:type" content="website" />
        <meta content="summary_large_image" name="twitter:card" />
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <link href="https://fonts.googleapis.com" rel="preconnect" />
        <link href="https://fonts.gstatic.com" rel="preconnect" crossorigin="anonymous" />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Montserrat:100,100italic,200,200italic,300,300italic,400,400italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic%7CExo:100,100italic,200,200italic,300,300italic,400,400italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic%7CLibre+Franklin:200,300,regular,600,800,900%7CIBM+Plex+Mono:regular"
          media="all"
        />
        <link
          href="https://uploads-ssl.webflow.com/63585b544e040a3c30bb76b9/6436dda6c3fbbf0c67d33a5b_favicon.jpg"
          rel="shortcut icon"
          type="image/x-icon"
        />
        <link
          href="https://uploads-ssl.webflow.com/63585b544e040a3c30bb76b9/6436ddcd2a48afc88e62aa4b_browser_icon.jpg"
          rel="apple-touch-icon"
        />
      </Helmet>
      <div className="content">
        <div className="container">
          <h1 className="content-header my-3">
            <strong>Terms of Service</strong>
          </h1>
          <h2 className="heading-8">
            <strong>1.&nbsp;Acceptance of Terms.&nbsp;</strong>
          </h2>
          <p className="paragraph-10">
            <strong>a.</strong> Gaybors Marketplace provides its Service (as defined below) to You
            through its website located at search.gayborsagency.com/search/ (the “Site”), subject
            to this TOS and the terms of Your Services Agreement. By accepting this TOS or by
            accessing or using the Service or Site, you acknowledge that You have read, understood,
            and agree to be bound by this TOS. In the event of a conflict of terms between this TOS
            and your Services Agreement, the terms of your Services Agreement shall govern your
            relationship solely with respect to any such conflicting terms. If You are entering
            into this Agreement on behalf of a company, business or other legal entity, you
            represent that You have the authority to bind such entity and its affiliates to this
            Agreement, in which case the terms “You” or “Your” shall refer to such entity and its
            affiliates. If You do not have such authority, or if You do not agree with this TOS,
            You must not accept this TOS and may not use the Service. As part of the registration
            process, You will be given one or more administrative user names and passwords for Your
            account(“Account”).
            <br />
            <br />
            <strong>b.</strong>
            Gaybors Marketplace may change this TOS from time to time by providing thirty (30) days
            prior notice either by emailing the email address associated with your Account or by
            posting a notice on the Site. You can review the most current version of this TOS at
            any time https://search.gayborsagency.com/search/terms-and-conditions The revised terms
            and conditions will become effective thirty (30) days after we post or send you notice
            of such changes, and if you use the Service after that date, your use will constitute
            acceptance of the revised terms and conditions.
          </p>
          <h2 className="heading-8">
            <strong>2.&nbsp;Description of Service.</strong>
          </h2>
          <p className="paragraph-10">
            The “Service” is a communication and productivity software aiming to enable creators as
            well as the people they partner with. The Service includes access to Gaybors
            Marketplace content and data (“Gaybors Marketplace Content”) such as graphs and other
            presentations of last communication, tasks, as well as data that is not generated or
            maintained by Gaybors Marketplace (“Third Party Data”).Notwithstanding anything to the
            contrary contained in this TOS or any Services Agreement, Gaybors Marketplace makes no
            representations or warranties with respect to Third Party Data presented on its
            platform. Any new features added to or augmenting the Service are also subject to this
            TOS.
          </p>
          <h2 className="heading-8">
            <strong>3.&nbsp;General Conditions/ Access and Use of the Service.&nbsp;</strong>
          </h2>
          <p className="paragraph-10">
            <strong>a. </strong>Subject to the terms and conditions of this TOS, You may access and
            use the Service only for permitted purposes. All rights, title and interest in and to
            the Service and its components will remain with and belong exclusively to Gaybors Marketplace. You shall not (i)sublicense, resell, rent, lease, transfer, assign, time share or
            otherwise commercially exploit or make the Service available to any third party;
            including without limitation, the development of a competing platform or access point
            for use or access to Gaybors Marketplace Content, Gaybors Marketplace Intellectual
            Property (as hereinafter defined), or the Third Party Data; (ii) use the Service in any
            unlawful manner (including without limitation in violation of any data, privacy or
            export control laws) or in any manner that interferes with or disrupts the integrity or
            performance of the Service or its components; (iii) modify, adapt or hack the Service
            to, or otherwise attempt to gain unauthorized access to the Service or its related
            systems or networks; (iv) reverse engineer, decompile, disassemble, or otherwise
            attempt to reconstruct or obtain the source code to all or any portion of the Service;
            (v) access or use the Service for purposes of monitoring availability, performance, or
            functionality, or for any other benchmarking or competitive purposes; or (vi) use any
            person, automated script, software, or any other fraudulent or deceptive means, to
            click or otherwise activate any form of view, action, response mechanism, annotation,
            or advertising unit, so as to increase views, impressions, actions, or skew results.
            You shall comply with any policies or other notices Gaybors Marketplace provides You or
            publishes in connection with the Service. You will notify Gaybors Marketplace
            immediately if You learn of any unauthorized use of any username and password or any
            other known or suspected breach of security.
            <br />
            <br />
            <strong>b. </strong>You will not use the Service to upload, download, display, perform,
            transmit, or distribute any content that is, nor will you engage in any activities that
            are, infringing, libelous, defamatory, erroneous, misleading, deceptive, offensive,
            hateful, obscene, pornographic, abusive, threatening, tortious, in violation of any
            privacy or intellectual property rights, or otherwise unlawful, or to transmit
            malicious code, viruses, time bombs, Trojan horses, or similar mechanisms, scripts,
            agents, bots, or programs.
            <br />
            <br />
            <strong>c. </strong>You agree not to access the Service by any means other than through
            the interface that is provided by Gaybors Marketplace for use in accessing the Service.
            Any rights not expressly granted herein are reserved and no license or right to use any
            trademark of Gaybors Marketplace or any third party is granted to You in connection
            with the Service. Gaybors Marketplace reserves the right to modify or discontinue,
            temporarily or permanently, the Service (or any part thereof) for any reason at all.
            <br />
            <br />
            <strong>d. </strong>You are responsible for maintaining the confidentiality of You
            rlogin, password and account and for all activities that occur under Your login or
            account. You are responsible for any content or data You provide to Gaybors Marketplace
            (“Your Content”) and Gaybors Marketplace reserves the right to access Your Account in
            order to respond to Your requests for technical support and to investigate suspicious
            activity.
            <br />
            <br />
            <strong>e. </strong>You understand that the operation of the Service, including Your
            Content, may be unencrypted and involve (i) transmissions over various networks; (ii)
            changes to conform and adapt to technical requirements of connecting networks or
            devices; and (iii)transmission to Gaybors Marketplace’s third party vendors and hosting
            partners to provide the necessary hardware, software, networking, storage, and related
            technology required to operate and maintain the Service. Accordingly, You acknowledge
            that You bear sole responsibility for adequate security, protection and backup of Your
            Content. Gaybors Marketplace will have no liability to You for any unauthorized access
            or use of any of Your Content, or any corruption, deletion, destruction or loss of any
            of Your Content. The Service may provide, or third parties may provide, links or other
            access to other sites and resources on the Internet. Gaybors Marketplace has no control
            over such sites and resources and Gaybors Marketplace is not responsible for anything
            contained therein or any result from use thereof. You further acknowledge and agree
            that Gaybors Marketplace will not be responsible or liable, directly or indirectly, for
            any damage or loss caused or alleged to be caused by or in connection with use of or
            reliance on any content, events, goods or services available on or through any such
            site or resource. Any dealings You have with third parties found while using the
            Service are between You and the third party, and You agree that Gaybors Marketplace is
            not liable for any loss or claim that You may have against any such third party.
            <br />
            <br />
            <strong>f. </strong>You shall be responsible for obtaining and maintaining any
            equipment and ancillary services needed to connect to, access or otherwise use the
            Service, including, without limitation, modems, hardware, server, software, operating
            system, networking, web servers, long distance and local telephone service
            (collectively, “Equipment”). You shall be responsible for ensuring that such Equipment
            is compatible with the Service and complies with all configurations and specifications
            set forth in Gaybors Marketplace’s published policies then in effect. You shall also be
            responsible for maintaining the security of the Equipment, Your Account, passwords
            (including but not limited to administrative and user passwords) and files, and for all
            uses of Your Account or the Equipment with or without Your knowledge or consent.
            <br />
            <br />
            <strong>g.</strong> You and Gaybors Marketplace will each have the right to include the
            other’s name on marketing materials that such party may prepare or distribute, subject
            to the prior written approval of the other party. In the event that such consent is
            granted, any such use by Gaybors Marketplace shall not imply or serve as an endorsement
            of Your goods or services.
            <br />
            <br />
            <strong>h. </strong>Gaybors Marketplace shall have the right collect and analyze data
            and other information relating to the provision, use and performance of various aspects
            of the Service, and related systems and technologies(including, without limitation,
            information concerning Your Content and data derived therefrom), and Gaybors
            Marketplace will be free(during and after the term hereof) to (i) use such information
            and data solely to improve and enhance the Service and for other development,
            diagnostic, and corrective purposes in connection with the Service and other Gaybors
            Marketplace offerings; and (ii) disclose such data solely in aggregate form and without
            identifying You or Your clients or disclosing personally identifiable information of
            any individual and solely in connection with its business.
            <br />
            <br />
            <strong>i. </strong>Gaybors Marketplace may use any reports, comments and suggestions
            in any form regarding the Service that You provide (collectively, the “Feedback”). You
            hereby grant Gaybors Marketplace a worldwide, non-exclusive, irrevocable, perpetual,
            royalty-free right and license to incorporate and use the Feedback.
            <br />
            <br />
            <strong>j. </strong>You acknowledge that Gaybors Marketplace may establish general
            practice sand limits concerning use of the Service, including without limitation the
            maximum storage space that will be allotted on Gaybors Marketplace’s servers on Your
            behalf. You agree that Gaybors Marketplace has no responsibility or liability for the
            deletion or failure to store any content maintained or obtained by the Service.
            <br />
            <br />
            <strong>k. </strong>The Service contains Third Party Data aggregated by Gaybors Marketplace from multiple sources. However, the nature of the Third Party Data and the
            collection processes limits the ability to independently verify and/or validate the
            Third Party Data and all Third Party Data is subject to change at any time without
            notice. Neither Gaybors Marketplace nor its Third Party Data sources warrant the
            comprehensiveness, completeness, accuracy or adequacy of the Third Party Data for any
            purpose. Gaybors Marketplace, its sources and their directors, employees, contractors,
            and agents disclaim all warranties, expressed or implied, as to any matter whatsoever
            and shall not be responsible for any loss or damage that may directly or indirectly
            arise as the result of the use of the Third Party Data contained in the Service.
            <br />
            <br />
            <strong>l. </strong>Gaybors Marketplace is not liable for the acts, errors, omissions,
            representations, warranties, breaches, or negligence of any Brand or Creator, or for
            any personal injuries, death, property damage, or other damages or expenses resulting
            therefrom.
          </p>
          <h2 className="heading-8">
            <strong>4.&nbsp;Payment.&nbsp;</strong>
          </h2>
          <p className="paragraph-10">
            You represent and warrant to Gaybors Marketplace that any payment information provided
            to Gaybors Marketplace is true, correct, and complete and that You are authorized to
            use the payment instrument. You will promptly update Your payment information with any
            changes (for example, a change in Your billing address or credit card expiration date)
            that may occur. You agree to pay Gaybors Marketplace the amount that is specified in
            the Services Agreement and payment plan in accordance with the terms of such plan and
            this Agreement. If You dispute any charges, You must let Gaybors Marketplace know
            within sixty (60) days after the date that Gaybors Marketplace invoices you. Gaybors
            Marketplace reserves the right to change the fee for the Service. If Gaybors
            Marketplace does change fee, Gaybors Marketplace will provide notice of the change to
            You at least thirty (30) days before the change is to take effect, during which time
            you may choose to terminate the Services Agreement upon written notice to Gaybors
            Marketplace. Your failure to terminate the Services Agreement within thirty (30) days
            after Gaybors Marketplace’s notice of the fee change constitutes your agreement to pay
            the changed amount. Unless otherwise stated in Your Services Agreement, full payment
            for invoices issued in any given month must be received by Gaybors Marketplace thirty
            (30) days after the mailing date of the invoice, or the Services Agreement may be
            terminated. You shall be responsible for all taxes associated with the Service other
            than taxes based on Gaybors Marketplace’s net income. In the event You fail to timely
            pay any amounts due under this Agreement, or if You otherwise breach this Agreement,
            Gaybors Marketplace reserves the right in its sole discretion to temporarily or
            permanently terminate your access to the Service.
          </p>
          <h2 className="heading-8">
            <strong>5.&nbsp;Representations and Warranties.&nbsp;&nbsp;</strong>
          </h2>
          <p className="paragraph-10">
            You represent and warrant to Gaybors Marketplace that (i) You have full power and
            authority to enter into this TOS and the Services Agreement; (ii) You own all Your
            Content or have obtained all necessary permissions, releases, rights or licenses
            required to allow Gaybors Marketplace to perform its obligations in connection with the
            Service without obtaining any further releases or consents; (iii) Your Content and
            other activities in connection with the Service, and Gaybors Marketplace’s exercise of
            all rights and license granted by You herein, do not and will not violate, infringe, or
            misappropriate any third party’s copyright, trademark, right of privacy or publicity,
            or other personal or proprietary right, nor does Your Content contain any matter that
            is defamatory, obscene, unlawful, threatening, abusive, tortious, offensive or
            harassing; and (iv) You are thirteen (13) years of age or older, and if you are not at
            least 18 years old, You have your parent or legal guardian’s permission to use the
            Service, and Your parent or legal guardian has read and agrees to this TOS on your
            behalf. If You are a parent or legal guardian of a user under the age of 18, you agree
            to be fully responsible for the acts or omissions of such user in connection with the
            Service. Persons under the age of 13 do not have permission to use the Service.
          </p>
          <h2 className="heading-8">
            <strong>6.&nbsp;Termination.&nbsp;&nbsp;&nbsp;</strong>
          </h2>
          <p className="paragraph-10">
            <strong>a.</strong> You may terminate this Agreement on sixty (60) days’ written notice
            to Gaybors Marketplace if any of the following events occur:
            <br />
            <br />
            <strong>i. </strong>upon the bankruptcy or insolvency of Gaybors Marketplace, or the
            filing against Gaybors Marketplace of a petition in bankruptcy, or the making of an
            assignment for the benefit of creditors by Gaybors Marketplace, or the appointment of a
            receiver or trustee for Gaybors Marketplace or any of the assets of Gaybors
            Marketplace, or the institution by or against Gaybors Marketplace of any other type of
            insolvency proceeding;
            <br />‍<br />
            <strong>ii.</strong>
            Gaybors Marketplace permanently ceases to offer the Service in its entirety; or
            <br />
            <br />
            <strong>iii.</strong>
            Gaybors Marketplace is in Material Breach (as defined herein) of this Agreement.
            “Material Breach” shall mean a complete outage of the Service for more than ten (10)
            consecutive business days.
            <br />
            <br />
            <strong>b.</strong> In addition to any other remedies Gaybors Marketplace may have,
            Gaybors Marketplace may terminate Your Account and this Agreement:
            <br />
            <br />
            <strong>i.</strong>
            At any time by providing thirty (30) days’ prior notice to the administrative email
            address associated with Your Account; or
            <br />
            <br />
            <strong>ii. </strong>Immediately with or without notice to You if You breach any of the
            terms or conditions of this Agreement.
            <br />
            <br />
            <strong>c.</strong> Gaybors Marketplace reserves the right to modify or discontinue,
            temporarily or permanently, the Service (or any part thereof) for any reason at all and
            may do so without notice in case of your breach of the TOS or any other agreement
            between you and Gaybors Marketplace. All of Your Content on the Service (if any) may be
            permanently deleted by Gaybors Marketplace upon any termination of Your Account in its
            sole discretion.
            <br />
            <br />
            <strong>d.</strong> If Gaybors Marketplace terminates Your account pursuant to Section
            6(b)(i), Gaybors Marketplace will refund the pro-rated, unearned portion of any amount
            that You have prepaid to Gaybors Marketplace for such Service.
            <br />
            <br />
            <strong>e.</strong>
            If Gaybors Marketplace terminates Your account pursuant to Section 6(b)(ii), Talent
            Pitch Pro may in its sole discretion retain any unearned portion of any amount that You
            have prepaid to Gaybors Marketplace for such Service, in addition to any other remedies
            Gaybors Marketplace may have.
            <br />
            <br />
            <strong>f.</strong> All of Gaybors Marketplace’s accrued rights to payment shall
            survive termination of this Agreement.
            <br />
            <br />
            <strong>g.</strong>
            Upon termination or expiration of this Agreement, You agree that You will promptly
            delete any and all Gaybors Marketplace Content, Gaybors Marketplace Intellectual
            Property, and Third Party Data obtained from the Service.
          </p>
          <h2 className="heading-8">
            <strong className="bold-text-12">
              7. DISCLAIMER OF WARRANTIES.&nbsp;&nbsp;&nbsp;
            </strong>
          </h2>
          <p className="paragraph-10">
            THE SERVICE MAY BE TEMPORARILY UNAVAILABLE FOR SCHEDULED MAINTENANCE OR FOR UNSCHEDULED
            EMERGENCY MAINTENANCE, EITHER BY Gaybors Marketplace OR BY THIRD-PARTY PROVIDERS, OR
            BECAUSE OF OTHER CAUSES BEYOND Gaybors Marketplace’S REASONABLE CONTROL. THE SERVICE,
            INCLUDING THE SITE AND Gaybors Marketplace CONTENT, AND ALL SERVER AND NETWORK
            COMPONENTS ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT ANY WARRANTIES
            OF ANY KIND, AND Gaybors Marketplace EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES,
            WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
            MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. YOU
            ACKNOWLEDGE THAT Gaybors Marketplace DOES NOT WARRANT THAT THE SERVICE WILL BE
            UNINTERRUPTED, TIMELY, SECURE, ERROR-FREE OR VIRUS-FREE, NOR DOES IT MAKE ANY WARRANTY
            AS TO THE RESULTS THAT MAY BE OBTAINED FROM USE OF THE SERVICE, AND NO INFORMATION,
            ADVICE OR SERVICES OBTAINED BY YOU FROM Gaybors Marketplace OR THROUGH THE SERVICE
            SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THIS TOS.
          </p>
          <h2 className="heading-8">
            <strong className="bold-text-12">8.&nbsp;LIMITATION OF LIABILITY.&nbsp;&nbsp;</strong>
          </h2>
          <p className="paragraph-10">
            UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (WHETHER IN CONTRACT, TORT, OR
            OTHERWISE) SHALL Gaybors Marketplace BE LIABLE TO YOU OR ANY THIRD PARTY FOR (A) ANY
            INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING
            LOST PROFITS, LOST SALES OR BUSINESS, LOST DATA, OR (B) FOR ANY DIRECT DAMAGES, COSTS,
            LOSSES OR LIABILITIES (INCLUDING ATTORNEYS’ FEES) IN EXCESS OF THE FEES ACTUALLY PAID
            BY YOU IN THE SIX (6) MONTHS PRECEDING THE EVENT GIVING RISE TO YOUR CLAIM OR, IF NO
            FEES APPLY, ONE HUNDRED ($100) U.S. DOLLARS. THE PROVISIONS OF THIS SECTION ALLOCATE
            THE RISKS UNDER THIS TOS AND THE SERVICES AGREEMENT BETWEEN THE PARTIES, AND THE
            PARTIES HAVE RELIED ON THESE LIMITATIONS IN DETERMINING WHETHER TO ENTER INTO THIS TOS
            AND THE SERVICES AGREEMENT. SOME STATES DO NOT ALLOW THE EXCLUSION OF IMPLIED
            WARRANTIES OR LIMITATION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, WHICH
            MEANS THAT SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU. IN THESE STATES, GAYBORS MARKETPLACE'S LIABILITY WILL BE LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW.
          </p>
          <h2 className="heading-8">
            <strong className="bold-text-12">9.&nbsp;Indemnification.&nbsp;</strong>
          </h2>
          <p className="paragraph-10">
            Subject to Section 8 herein (Limitation of Liability), each Party (the “Indemnifying
            Party”) shall defend, indemnify, and hold harmless the other Party (the “Indemnified
            Party”) from and against any third-party claims, actions or demands, including without
            limitation reasonable legal and accounting fees, arising or resulting from the
            Indemnifying Party’s breach of this Agreement. The Indemnified Party shall provide
            notice to the Indemnifying Party of any such claim, suit or demand. The Indemnifying
            Party reserves the right to assume the exclusive defense and control of any matter
            which is subject to indemnification under this section. In such case, the Indemnified
            Party agrees to cooperate with any reasonable requests assisting the Indemnifying
            Party’s defense of such matter.
          </p>
          <h2 className="heading-8">
            <strong className="bold-text-12">10.&nbsp;Copyright Policy.&nbsp;&nbsp;</strong>
          </h2>
          <p className="paragraph-10">
            Gaybors Marketplace does not condone nor authorize activities on or through the Service
            that infringe copyright or intellectual property rights. Gaybors Marketplace will
            delete Your Content if properly notified that Your Content infringes on another’s
            intellectual property rights. If you are a copyright owner or an agent thereof and
            believe that any content infringes upon your copyrights, you may submit a notification
            pursuant to the Digital Millennium Copyright Act (“DMCA”) by providing our Copyright
            Agent with the following information in writing (see 17 U.S.C §512(c)(3) for further
            detail):
            <br />
            <strong>
              ‍<br />· An electronic or physical signature of the person authorized to act on
              behalf of the owner of the copyright’s interest;
              <br />· A description of the copyrighted work that you claim has been infringed;
              <br />· A description of the material that you claim is infringing and where it is
              located on the Service;
              <br />· Identification of the URL or other specific location on the Service where the
              material that you claim is infringing is located;
              <br />· Your address, telephone number, and email address;
              <br />· A statement by you that you have a good faith belief that the disputed use is
              not authorized by the copyright owner, its agent, or the law; and
              <br />· A statement by you, made under penalty of perjury, that the above information
              in your notice is accurate and that you are the copyright owner or authorized to act
              on the copyright owner’s behalf.
            </strong>
            <br />
            <br />
            You can contact our Copyright Agent via email at&nbsp;inquires@talentpitchpro.com
            <br />
            <br />
            Gaybors Marketplace reserves the right to terminate Your Account or any user account
            that it determines to be “repeat infringers.” A repeat infringer is a user who has
            repeatedly been notified of infringing activity and/or has had User Content repeatedly
            removed from the Service.
          </p>
          <h2 className="heading-8">
            <strong className="bold-text-12">11.&nbsp;Intellectual Property.&nbsp;&nbsp;</strong>
          </h2>
          <p className="paragraph-10">
            You acknowledge and agree that the copyright, trademark, trade secrets and any other
            intellectual property rights arising out of or related to the Service, including but
            not limited to any rights arising out of the compilation and presentation of Third
            Party Data (the “Gaybors Marketplace Intellectual Property”) is and shall remain with
            Gaybors Marketplace. You acknowledge that such Gaybors Marketplace Intellectual
            Property, regardless of form or format, is proprietary to Gaybors Marketplace,
            including (i) works of original authorship such as compiled information containing
            Gaybors Marketplace’s selection, arrangement and coordination and expression of such
            information or pre-existing material it has created, gathered or assembled; (ii)
            confidential or trade secret information; and (iii) information that has been created,
            developed and maintained by Gaybors Marketplace at great expense of time and money such
            that misappropriation or unauthorized use by others for commercial gain would unfairly
            and irreparably harm Gaybors Marketplace. You shall not commit or permit any act or
            omission by Your agents, employees or any third party that would impair Gaybors
            Marketplace’s proprietary and intellectual property rights in Gaybors Marketplace
            Intellectual Property. You agree to notify Gaybors Marketplace immediately upon
            obtaining any information regarding a threatened or actual infringement of Gaybors
            Marketplace’s Intellectual Property.
          </p>
          <h2 className="heading-8">
            <strong className="bold-text-12">
              12.&nbsp;Confidential Information.&nbsp;&nbsp;
            </strong>
          </h2>
          <p className="paragraph-10">
            <strong>a.</strong> The Parties shall treat all information, tangible and intangible,
            received in connection with this Agreement, including but not limited to either Party’s
            business, policies, procedures, plans, services, products, intellectual property, data,
            customers, suppliers, methods, research, inventions, ideas or property and any and all
            demographic and psychographic information, including information made available by
            either Party before the Subscription Date (collectively, “Confidential Information”),
            as strictly confidential and proprietary information. Confidential Information shall
            not include information that either Party can demonstrate: (i) is already publicly
            known when it is received or which is subsequently made public by the disclosing Party;
            (ii) was independently developed by the receiving Party without reference to
            Confidential Information; or (iii) was known by the receiving Party prior to being
            received from the disclosing Party and which was not subject to an obligation of
            confidentiality.
            <br />
            <br />
            <strong>b.</strong>
            The Parties shall, during the term of this Agreement and for a period of two (2) years
            from the termination or expiration of this Agreement: (i) protect and safeguard the
            confidentiality of the disclosing Party’s Confidential Information with at least the
            same degree of care as the receiving Party would protect its own Confidential
            Information, but in no event with less than a commercially reasonable degree of care;
            (ii) not use the disclosing Party’s Confidential Information, or permit it to be
            accessed or used, for any purpose other than to exercise its rights or perform its
            obligations under this Agreement; and (iii) not disclose any such Confidential
            Information to any person, except to the receiving Party’s employees and contractors
            who need to know the Confidential Information to assist the receiving Party, or act on
            its behalf, to exercise its rights or perform its obligations under this Agreement. The
            receiving Party shall be responsible for any breach of this Section 12 caused by any of
            its employees or contractors. Each Party shall destroy all Confidential Information of
            the other Party immediately upon termination of this Agreement, including any notes,
            copies or other records containing or reflecting any Confidential Information.
          </p>
          <h2 className="heading-8">
            <strong className="bold-text-12">13.&nbsp;Assignment.&nbsp;&nbsp;</strong>
          </h2>
          <p className="paragraph-10">
            Neither Party may assign this TOS or any Services Agreement without the prior written
            consent of the other Party; provided, however, that either Party may assign or transfer
            this TOS and Services Agreement without the other Party’s prior consent, in the event
            of a change in control of such Party, including but not limited to any merger,
            acquisition, or sale of all or substantially all of such Party’s assets.
          </p>
          <h2 className="heading-8">
            <strong className="bold-text-12">14.&nbsp;Miscellaneous.&nbsp;&nbsp;</strong>
          </h2>
          <p className="paragraph-10">
            If any provision of this TOS or the Services Agreement is found to be unenforceable or
            invalid, that provision will be limited or eliminated to the minimum extent necessary
            so that this TOS or the Services Agreement will otherwise remain in effect and
            enforceable. Except with respect to any Services Agreements executed by the parties,
            both parties agree that this TOS is the complete and exclusive statement of the mutual
            understanding of the parties and supersedes and cancels all previous written and oral
            agreements, communications and other understandings relating to the subject matter of
            this TOS, and that all waivers and modifications must be in a writing signed by both
            parties, except as otherwise provided herein. The failure of either party to exercise
            or enforce any right or provision of this TOS shall not be a waiver of that right. No
            agency, partnership, joint venture, or employment is created as a result of this TOS or
            any Services Agreement and neither Party has any authority of any kind to bind the
            other Party in any respect whatsoever. In any action or proceeding to enforce rights
            under this TOS, the prevailing party will be entitled to recover costs and attorneys’
            fees. All notices under this TOS will be in writing and will be deemed to have been
            duly given once sent.
          </p>
          <h2 className="heading-8">
            <strong className="bold-text-12">15.&nbsp;Governing Law.&nbsp;&nbsp;&nbsp;</strong>
          </h2>
          <p className="paragraph-10">
            This TOS and any Services Agreement shall be governed by the laws of the State of Texas
            without regard to the principles of conflicts of law. Each Party hereby expressly
            agrees to submit to the exclusive personal jurisdiction of the federal and state courts
            of the State of Texas for the purpose of resolving any dispute relating to Your access
            to or use of the Service.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Terms
