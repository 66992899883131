import _ from 'lodash'
import { Link, useParams } from 'react-router-dom'
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/hooks'

import RenderResultsTable from 'RenderResultsTable'
import { useEffect } from 'react'
import { fetchCampaign, toggleInfluencer, editCampaign } from 'redux/campaignsSlice'
import { safelyMillify } from 'utils'
import SocialIcon from 'SocialIcon'
import { showToast } from 'utils'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { useState } from 'react'
import axios from 'axios'

const CampaignDetail = () => {
  const params = useParams()

  const dispatch = useDispatch()

  const [editCampaignTitle, setEditCampaignTitle] = useState(false)

  useEffect(() => {
    dispatch(fetchCampaign(params.campaignId))
  }, [])

  const campaign = useSelector((state) => state.campaigns.entities[params.campaignId])

  const socials = useSelector((state) => state.socials.entities)
  const influencers = useSelector((state) => state.influencers.entities)

  let columns = [
    {
      name: 'Talent',
      size: '5',
      sortValue: ({ social, influencer }) => {
        return influencer.fullname || social.platform_account_nickname
      },
      data: ({ social, influencer }) => {
        const genderAge = [
          influencer.gender ? influencer.gender[0] : null,
          influencer.age_range ? influencer.age_range : null,
        ]
          .filter(Boolean)
          .join(' / ')

        return (
          <div className="row align-items-center">
            <div className="col-2">
              <button
                className="btn btn-light"
                onClick={() => {
                  dispatch(
                    toggleInfluencer({
                      campaign_id: params.campaignId,
                      influencer_id: influencer.id,
                    }),
                  ).then(() => {
                    dispatch(fetchCampaign(params.campaignId))
                  })
                  showToast({ customText: 'Campaign Updated' })
                }}
              >
                Remove
              </button>
            </div>
            <div className="col-3">
              <Link className="text-decoration-none" to={`/search/influencers/${influencer.id}`}>
                <img
                  style={{
                    width: '100px',
                    height: '100px',
                  }}
                  src={social.picture_main_url}
                  alt={influencer.fullname}
                  className="rounded-circle"
                />
              </Link>
            </div>
            <div className="col-7">
              <Link className="text-decoration-none" to={`/search/influencers/${influencer.id}`}>
                <div className="d-flex flex-column justify-content-between talent-name text-black">
                  <div className="fw-bold fullname">{influencer.fullname}</div>
                  {!_.isEmpty(genderAge) && (
                    <div
                      style={{
                        fontSize: '.85em',
                      }}
                    >
                      {genderAge}
                    </div>
                  )}
                  <div className="text-blue">
                    View Profile <span className="pb-1 text-blue fs-3">→</span>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        )
      },
    },
    {
      name: 'Socials',
      size: '7',
      data: ({ influencer }) => {
        const non_primary_accounts = influencer?.non_primary_accounts || []
        const social_accounts = [influencer.primary_account_id, ...non_primary_accounts].slice(
          0,
          4,
        )
        return (
          <div className="row align-items-center">
            {social_accounts.map((socialId, idx) => {
              const social = socials[socialId]
              if (!social) return null
              return (
                <div key={`social-${idx}`} className="col-3">
                  <div className="d-flex flex-column align-items-center">
                    <div className="text-black">
                      <SocialIcon platform={social.platform} />
                      <span className="ps-2">{social.platform_account_nickname}</span>
                    </div>
                    <div className="text-black">{safelyMillify(social?.followers)}</div>
                    {/* <div className="text-black-50">Followers</div> */}
                  </div>
                </div>
              )
            })}
          </div>
        )
      },
    },
  ].filter(Boolean)

  if (!campaign) return null

  const results = campaign.influencers
    .map((influencer_id) => {
      const influencer = influencers[influencer_id]
      if (!influencer) return null

      return {
        influencer_id: influencer.id,
        social_account_id: influencer.primary_account_id,
      }
    })
    .filter(Boolean)

  return (
    <>
      <div className="container">
        <div className="d-flex flex-row my-3">
          <p style={{ fontSize: '1.2em' }}>
            <Link
              data-purpose="roster-view-public-collection-link"
              to={`/search/campaign/share/${campaign.id}`}
            >
              Public Link for Sharing
            </Link>
            <OverlayTrigger
              placement={'right'}
              overlay={
                <Tooltip id={`tooltip-skews-male`}>
                  Anyone you share the link with can view the talent you added
                </Tooltip>
              }
            >
              <span style={{ minWidth: '8px', minHeight: '8px' }}>
                <AiOutlineInfoCircle className="ms-1" />
              </span>
            </OverlayTrigger>
          </p>
        </div>
      </div>
      <div className="container mb-4">
        <div className="row">
          <div className="col-12">
            {editCampaignTitle ? (
              <form
                onSubmit={(e) => {
                  e.preventDefault()
                  setEditCampaignTitle(false)
                  axios
                    .post('/api/directory/campaigns/edit-title', {
                      id: campaign.id,
                      title: campaign.title,
                    })
                    .then((res) => {
                      showToast({ customText: 'Campaign Title Updated' })
                      console.log(res.data)
                    })
                }}
              >
                <div className="form-group row">
                  <div className="col-md-8">
                    <label htmlFor="campaignName" className="col-form-label text-bold">
                      Campaign Title
                    </label>{' '}
                    <input
                      value={campaign.title}
                      autoFocus
                      onChange={(e) => {
                        dispatch(
                          editCampaign({
                            id: campaign.id,
                            changes: {
                              title: e.target.value,
                            },
                          }),
                        )
                      }}
                      type="text"
                      className="form-control"
                      id="campaignName"
                      placeholder="Enter the name of your campaign"
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-2">
                    <button
                      className="btn btn-primary"
                      style={{ transition: '0.3s', width: '100%' }}
                      onMouseOver={(e) => (e.currentTarget.style.transform = 'scale(1.05)')}
                      onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                    >
                      Save Title
                    </button>
                  </div>
                </div>
              </form>
            ) : (
              <h1>
                {campaign.title}{' '}
                <button
                  onClick={() => {
                    setEditCampaignTitle(true)
                  }}
                  className="btn btn-link"
                >
                  Change
                </button>
              </h1>
            )}
          </div>
        </div>
      </div>

      <RenderResultsTable
        columns={columns}
        results={results}
        blankMessage={'No talent added yet!'}
      />
    </>
  )
}

export default CampaignDetail
